import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Game } from '../../Game';
import { Texture } from 'pixi.js';
import { BUTTONS_ASSETS } from '../../assetsLoader/ButtonsLoader';
import { getEvent, EventType } from '../../../GameEventBus';
import { Sounds } from '../../classes/SoundController';
import { SoundNames } from '../../constants/sounds';

class ButtonInfo {
    game: Game;
    btnInfo: PIXI.Container;
    btnInfoDefault: PIXI.Sprite;
    btnInfoHidden: PIXI.Sprite;
    btnInfoHover: PIXI.Sprite;
    pointerIsDown: boolean;
    pointerIsOver: boolean;

    constructor(game: Game) {
        this.game = game;
        this.btnInfo = new PIXI.Container();
        this.btnInfoDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BTN_INFO_DEFAULT));
        this.btnInfoHidden = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BTN_INFO_HIDDEN));
        this.btnInfoHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BTN_INFO_HOVER));
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    init = () => {
        this.setupBtnInfoContainer();
        this.setupBtnInfoDefault();
        this.setupBtnInfoHover();

        this.game.assetsManager.gameContainer.addChild(this.btnInfo);
        this.game.resizeCallbacks.push(this.resize)
    }

    setupBtnInfoContainer = () => {
        this.btnInfo.width = 60;
        this.btnInfo.height = 60;
        this.btnInfo.x = 462;
        this.btnInfo.y = 972;
        this.btnInfo.name = "BTN_INFO";
        this.btnInfo.interactive = true;
        this.btnInfo.cursor = 'pointer';
        this.btnInfo
            .on('pointerup', this.doPointerUp)
            .on('pointerupoutside', this.doPointerUpOutside)
            .on('touchstart', this.openPaytable)
            .on('click', this.openPaytable)
            .on('pointerover', this.doPointerOver)
            .on('pointerout', this.doPointerOut)
    }

    setupBtnInfoDefault = () => {
        this.btnInfo.addChild(this.btnInfoDefault);
    }

    setupBtnInfoHidden = () => {
        this.btnInfoHidden.alpha = 0;
        this.btnInfo.addChild(this.btnInfoHidden);
    }

    setupBtnInfoHover = () => {
        this.btnInfoHover.alpha = 0;
        this.btnInfo.addChild(this.btnInfoHover);
    }

    openPaytable = () => {
        this.doPointerDown();
        getEvent(EventType.OPEN_PAYTABLE_MODAL).send(true)
    }

    doPointerOver = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnInfoHover,
            0.3,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.pointerIsOver = true;
    }

    doPointerOut = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnInfoHover,
            0.3,
            {alpha: 1},
            {alpha: 0, repeat: 0}
        );
        wiggleTween.fromTo(
            this.btnInfoDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }

    doPointerDown = () => {
        Sounds.play(SoundNames.BTN_UI);
        let wiggleTween2 = gsap.timeline();
        wiggleTween2.fromTo(
            this.btnInfoHover,
            0,
            {alpha: 1},
            {alpha: 0, repeat: 0}
        );
        wiggleTween2.fromTo(
            this.btnInfoDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.pointerIsDown = true;
    }

    doPointerUp = () => {
        if (this.pointerIsOver) {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.btnInfoHover,
                0,
                {alpha: 0},
                {alpha: 1, repeat: 0}
            );
            wiggleTween.fromTo(
                this.btnInfoDefault,
                0,
                {alpha: 1},
                {alpha: 0, repeat: 0}
            );
        } else {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.btnInfoDefault,
                0,
                {alpha: 0},
                {alpha: 1, repeat: 0}
            );
        }
        this.pointerIsDown = false;
    }

    doPointerUpOutside = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnInfoDefault,
            0,
            {alpha: 0},
            {alpha: 1, repeat: 0}
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    resize = () => {
        if (window.innerWidth <= 1080) {
            this.btnInfo.visible = false;
        } else {
            this.btnInfo.visible = true;
        }
    }

}

export default ButtonInfo;
