import { FC } from 'react';
import styled from 'styled-components';
import { reelsSpecialSymbolList, reelsSymbolList } from './constants';
import { respondHeightFrom } from '../../../styles/mixins';

const PaytableFirstPage: FC = () => {
	return (
		<>
			<Title>PAYTABLE</Title>
			<SymbolsContainer>
				{reelsSymbolList.map((item, index) => (
					<SymbolsText
						key={index}
						dangerouslySetInnerHTML={{ __html: item.text }}
					></SymbolsText>
				))}
			</SymbolsContainer>
			<SymbolsContainer>
				{reelsSpecialSymbolList.map((item, index) => (
					<SymbolsText
						style={{
							flexDirection: 'column',
							textAlign: 'center',
							maxWidth: '240px',
						}}
						key={index}
						dangerouslySetInnerHTML={{ __html: item.text }}
					></SymbolsText>
				))}
			</SymbolsContainer>
		</>
	);
};

export default PaytableFirstPage;

const Title = styled.h2`
	text-align: center;
	color: #fff;
	font-weight: 700;
	font-size: 38px;
	line-height: 38px;
	padding-bottom: 20px;
	${respondHeightFrom.base`
		padding-bottom: 0;
	`}
`;

const SymbolsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	max-width: 1000px;
`;

const SymbolsText = styled.div`
	font-weight: 700;
	font-size: 18px;
	padding: 10px;
	display: flex;
	align-items: center;
	img {
		margin: 5px 0;
		height: 100px;
	}
	div {
		padding-right: 10px;
	}
`;
