import { FC } from 'react';
import styled from 'styled-components';
import { respondHeightFrom } from '../../../styles/mixins';

const RulesFirstPage: FC = () => {
	return (
		<Container>
			<Title style={{ fontSize: 38 }}>GAME RULES</Title>

			<br />
			<br />

			<Title>Game description:</Title>
			<Text>
				Ivar the Boneless is a 5-reel, 4-row video slot with 1024 ways payouts.
			</Text>

			<br />
			<br />

			<Title>Respin feature:</Title>
			<Text>Landing a chest symbol on the last reel awards a respin.</Text>
			<Text>
				The respin has between 1 and 5 of the following boosts:
				<br />
				● a win multiplier of x2, x3 or x5
				<br />
				● one of the symbol types turns into wild (can be awarded multiple
				times)
				<br />● one of the low pays is removed from the reels (can be awarded
				multiple times)
			</Text>

			<br />
		</Container>
	);
};

export default RulesFirstPage;

const Title = styled.h2`
	color: #fff;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	padding-bottom: 20px;
	${respondHeightFrom.base`
		padding-bottom: 0;
	`}
`;

const Container = styled.div`
	flex-direction: column;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
`;

const Text = styled.div`
	padding: 5px;
	color: #fff;
	display: flex;
	font-weight: 700;
	font-size: 18px;
`;
