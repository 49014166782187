import { FC } from 'react';
import styled from 'styled-components';
import { respondHeightFrom } from '../../../styles/mixins';
import chest from '../../../game/assets/symbols/CHEST.png';
import MultiplierIncrease5 from '../../../game/assets/minigame/features/MultiplierIncrease5.png';
import SymbolWildH1 from '../../../game/assets/minigame/features/SymbolWildH1.png';
import SymbolRemoveL1 from '../../../game/assets/minigame/features/SymbolRemoveL1.png';
import winLines from '../../../assets/img/menu-modal/win-lines.png';

const PaytableSecondPage: FC = () => {
	return (
		<>
			<Title>1024 WAYS TO WIN</Title>
			<ReelsText style={{ flexDirection: 'column' }}>
				<img
					style={{ maxHeight: '140px', marginBottom: '10px' }}
					src={winLines}
					alt='Free Spins'
				/>
				Wins are awarded for identical symbols landing on 3 or more consecutive
				reel, starting from the leftmost one.
			</ReelsText>

			<Title style={{ paddingTop: '30px' }}>RESPIN FEATURE</Title>
			<ReelsContainer>
				<ReelsText>
					Land a chest symbol{' '}
					<img style={{ maxWidth: '70px' }} src={chest} alt='Free Spins' /> on
					the last reel in base game to trigger a respin
				</ReelsText>
				<ReelsText>
					The respin has between 1 and 5 of the following boosts:
				</ReelsText>

				<ReelsText>
					<img src={MultiplierIncrease5} alt='Free Spins' /> a win multiplier of
					x2, x3 or x5
				</ReelsText>
				<ReelsText>
					<img src={SymbolWildH1} alt='Free Spins' /> one of the symbol types
					turns into wild (can be awarded multiple times)
				</ReelsText>
				<ReelsText>
					<img src={SymbolRemoveL1} alt='Free Spins' /> one of the low pays is
					removed from the reels (can be awarded multiple times)
				</ReelsText>
			</ReelsContainer>
		</>
	);
};

export default PaytableSecondPage;

const Title = styled.h2`
	text-align: center;
	color: #fff;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	padding-bottom: 20px;
	${respondHeightFrom.base`
		padding-bottom: 0;
	`}
`;

const ReelsContainer = styled.div`
	flex-direction: column;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
`;

const ReelsText = styled.div`
	padding: 5px;
	display: flex;
	font-weight: 700;
	font-size: 18px;
	align-items: center;
	justify-content: center;
	img {
		margin: 0 10px;
		max-height: 70px;
		align-self: center;
	}
`;
