import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application, Texture } from 'pixi.js';
import infinity from '../../../assets/icons/infinity-count-for-btn.svg';
import { Game } from "../../Game";
import { BUTTONS_ASSETS } from "../../assetsLoader/ButtonsLoader";
import { EventType, getEvent } from '../../../GameEventBus';
import { Sounds } from '../../classes/SoundController';
import { SoundNames } from '../../constants/sounds';
import { Timer } from '../../../utils/Timer';


class AutoPlayButton {
    app: Application;
    game: Game;
    button: PIXI.Container;
    buttonBackground: PIXI.Sprite;
    buttonBorder: PIXI.Sprite;
    buttonIcon: PIXI.Sprite;
    buttonHover: PIXI.Sprite;
    buttonActive: PIXI.Sprite;
    pointerIsDown: boolean;
    pointerIsOver: boolean;
    isModalActive: boolean;
    buttonLabel: PIXI.Text;
    infinityIcon?: PIXI.Sprite;
    autoSpinCount: number | string;

    constructor(game: Game) {
        this.game = game;
        this.app = game.app;
        this.pointerIsDown = false;
        this.pointerIsOver = false;
        this.isModalActive = false;
        this.button = new PIXI.Container();
        this.buttonBackground = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.AUTO_PLAY_BTN_BG));
        this.buttonBorder = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.AUTO_PLAY_BTN_BORDER));
        this.buttonIcon = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.AUTO_PLAY_BTN_ICON));
        this.buttonHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.AUTO_PLAY_BTN_ICON_HOVER));
        this.buttonActive = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.AUTO_PLAY_BTN_ICON_ACTIVE));
        this.buttonLabel = new PIXI.Text('', {
            fontSize: 20,
            fontWeight: '900',
            fill: '#151515',
        });
        this.autoSpinCount = this.game.assetsManager.autoPlayBtn?.autoSpinCount ? this.game.assetsManager.autoPlayBtn?.autoSpinCount : '';
    }

    init = () => {
        this.game.resizeCallbacks.push(this.resize)
        if (!this.game.assetsManager.gameContainer) return;
        this.button.x = 1670;
        this.button.y = 725;
        this.button.width = 120;
        this.button.height = 120;
        this.button.name = "AUTO_PLAY_BTN";
        this.button.hitArea = new PIXI.Circle(0, 0, 60);

        this.buttonBackground.y = 0;
        this.buttonBackground.x = 0;

        this.buttonBorder.x = 0;
        this.buttonBorder.y = 0;
        this.buttonBorder.width = 104;
        this.buttonBorder.height = 104;

        this.buttonIcon.x = 0;
        this.buttonIcon.y = 0;
        this.buttonIcon.width = 34;
        this.buttonIcon.height = 36.27;

        this.buttonHover.x = 0;
        this.buttonHover.y = 0;
        this.buttonHover.width = 33;
        this.buttonHover.height = 36.27;
        this.buttonHover.alpha = 0;

        this.buttonActive.x = 5;
        this.buttonActive.y = 0;
        this.buttonActive.width = 57;
        this.buttonActive.height = 63.98;
        this.buttonActive.alpha = 0;

        this.buttonLabel.x = -5;
        this.buttonLabel.y = 0;
        this.buttonLabel.height = 25;

        const infinityImage = PIXI.Texture.from(infinity);
        this.infinityIcon = new PIXI.Sprite(infinityImage);
        this.infinityIcon.visible = false;

        this.infinityIcon.x = -5;
        this.infinityIcon.y = 0;
        this.infinityIcon.height = 16;

        this.buttonBackground.anchor.set(0.5);
        this.buttonIcon.anchor.set(0.5);
        this.buttonHover.anchor.set(0.5);
        this.buttonActive.anchor.set(0.5);
        this.buttonBorder.anchor.set(0.5);
        this.buttonLabel.anchor.set(0.5);
        this.buttonLabel.zIndex = 1000
        this.infinityIcon.anchor.set(0.5);

        this.game.assetsManager.gameContainer.addChild(this.button!);

        this.button.addChild(this.buttonBackground!);
        this.button.addChild(this.buttonBorder!);
        this.button.addChild(this.buttonIcon!);
        this.button.addChild(this.buttonHover!);
        this.button.addChild(this.buttonActive!);
        this.buttonActive.addChild(this.buttonLabel!);
        this.buttonActive.addChild(this.infinityIcon!);
        this.button.interactive = true;
        this.button.buttonMode = true;
        this.game.inactiveElementsWhileSpin.push(this.button)
        this.button
            .on('pointerup', () => this.doPointerUp())
            .on('pointerupoutside', () => this.doPointerUpOutside())
            .on('touchstart', () => this.doPointerDown())
            .on('click', e => this.leftClick(e))
            .on('pointerover', () => this.doPointerOver())
            .on('pointerout', () => this.doPointerOut());

    };

    leftClick = (e: any) => {
        if (e.data.button === 0) {
            this.doPointerDown();
        }
    };

    setButtonState = (isActive: boolean) => {
        if (isActive) {
            this.buttonActive.alpha = 1;
            this.buttonIcon.visible = false;
            this.buttonHover.visible = false;
        } else {
            this.buttonActive.alpha = 0;
            this.buttonIcon.visible = true;
            this.buttonHover.visible = true;
        }
    };

    changeFSMode = (status: boolean) => {
        if (status) {
            this.button.interactive = false;
            this.button.buttonMode = false;
            this.button.alpha = 0;
        } else {
            this.button.interactive = true;
            this.button.buttonMode = true;
            this.button.alpha = 1;
        }
    }

    setSpinCounter = (value: number | string) => {
        this.buttonLabel.text = value;
        
        if (value === 0) {
            getEvent(EventType.AUTOSPIN_IN_PROGRESS).send(false);
            const resetCount = new Timer(() => {
                this.setButtonState(false)
            }, 300)
            resetCount.initialize();
        }

        if (value === 'ထ') {
            this.buttonLabel.visible = false;
            this.infinityIcon!.visible = true;
        } else {
            this.buttonLabel.visible = true;
            this.infinityIcon!.visible = false;
        }
    };

    closeModal(isActive: boolean) {
        this.isModalActive = isActive;
    }

    doPointerUp() {
        if (this.pointerIsOver) {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.buttonIcon,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
        } else {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.buttonIcon,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
        }
        this.pointerIsDown = false;
    }

    doPointerUpOutside() {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.buttonIcon,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    doPointerDown() {
        Sounds.play(SoundNames.BTN_UI);
        this.isModalActive = !this.isModalActive;
        this.pointerIsDown = true;
        getEvent(EventType.SHOW_AUTO_PLAY_MODAL).send(true); 
    }

    doPointerOver() {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.buttonBorder,
            0.3,
            { rotation: this.buttonBorder.rotation },
            { rotation: this.buttonBorder.rotation + Math.PI, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.buttonHover,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsOver = true;
    }

    doPointerOut() {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.buttonBorder,
            0.3,
            { rotation: this.buttonBorder.rotation },
            { rotation: this.buttonBorder.rotation - Math.PI, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.buttonHover,
            0,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.buttonIcon,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }

    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            if (this.game.leftHandedMode) {
                this.button.scale.set(2.38);
                this.button.y = 2500;
                this.button.x = 500;
            } else {
                this.button.scale.set(2.38);
                this.button.y = 2500;
                this.button.x = 1430;
            }

        } else {
            if (window.innerWidth <= 1080) {
                if (this.game.leftHandedMode) {
                    this.button.scale.set(1.7);
                    this.button.y = 140;
                    this.button.x = 190;
                } else {
                    this.button.scale.set(1.7);
                    this.button.y = 140;
                    this.button.x = 1740;
                }

            } else {
                this.button.scale.set(1)
                this.button.x = 1779
                this.button.y = 725;
            }
        }

    }
}

export default AutoPlayButton;
