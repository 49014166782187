import { FC } from 'react';
import styled from 'styled-components';
import { respondHeightFrom } from '../../../styles/mixins';

import buyBtn from '../../../game/assets/minigame/modal/buy_extra_pick_button.png';
import buyBonusBtn from '../../../game/assets/buttons/buy-bonus-btn-default.png';

interface IProps {
	fourthFeaturePrice: number;
	fifthFeaturePrice: number;
}

const PaytableFourthPage: FC<IProps> = ({
	fourthFeaturePrice,
	fifthFeaturePrice,
}) => {
	return (
		<ReelsContainer>
			<ReelsContainer style={{ flexDirection: 'column', maxWidth: '350px' }}>
				<Title>BUY BONUS</Title>
				<ReelsText style={{ flex: 'auto' }}>
					You can buy free spins with 3 boosts picks for 100 x bet
				</ReelsText>
				<ReelsText>
					<img
						style={{ maxHeight: '140px' }}
						src={buyBonusBtn}
						alt='Free Spins'
					/>
				</ReelsText>
			</ReelsContainer>

			<ReelsContainer style={{ flexDirection: 'column' }}>
				<Title>BUY EXTRA BOOST PICKS IN FREE SPINS</Title>
				<ReelsText style={{ flex: 'auto' }}>
					You can buy 4th boost pick for {fourthFeaturePrice} x bet. <br />
					And 5th boost pick for {fifthFeaturePrice} x bet. <br />
					5 picks is maximum <br />
					Extra picks must be purchased before the first chest is choosen{' '}
				</ReelsText>
				<ReelsText style={{ padding: '40px 0' }}>
					<img src={buyBtn} alt='Free Spins' />
				</ReelsText>
			</ReelsContainer>
		</ReelsContainer>
	);
};

export default PaytableFourthPage;

const Title = styled.h2`
	text-align: center;
	color: #fff;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	padding-bottom: 10px;
	${respondHeightFrom.base`
		padding-bottom: 0;
	`}
`;

const ReelsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 0 40px;
	justify-content: space-around;
`;

const ReelsText = styled.div`
	padding: 10px;
	display: flex;
	font-weight: 700;
	font-size: 18px;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	img {
		margin: 10px;
		max-height: 70px;
		align-self: center;
	}
`;
