import { FC, useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Popover } from '@mantine/core';
import { Howler } from 'howler';
import iconAudio from '../../assets/img/header/icon-audio.svg';
import iconVisualEffects from '../../assets/img/header/icon-visual-effects.svg';
import { LocalStorage } from '../../utils/localStorage';
import { EventType, getEvent } from '../../GameEventBus';
import { Sounds } from '../../game/classes/SoundController';
import { SoundNames } from '../../game/constants/sounds';

type ComponentProps = {
    isSoundOn: boolean;
    setIsSoundOn: (value: boolean) => void;
}

const isTurbo = JSON.parse(localStorage.getItem("isTurboEnabled") || 'false')
const Settings: FC<ComponentProps> = ({isSoundOn, setIsSoundOn}) => {
    const [isVisualEffectsOn, setIsVisualEffectsOn] = useState(isTurbo);
    const [disableTurboBtn, setDisableTurboBtn] = useState(false);

    const changeAudioMode  = useCallback((value: boolean) => {
      if (value) {
          Howler.volume(0.5);
          getEvent(EventType.AUDIO_TOGGLE).send(value);
          LocalStorage.setItem('isAudioEnable', value)
        } else {
          Howler.volume(0);
          getEvent(EventType.AUDIO_TOGGLE).send(value);
          LocalStorage.setItem('isAudioEnable', value)
        }
        setIsSoundOn(value)
      }, [setIsSoundOn])
      
      const changeEffects = useCallback((value: boolean) => {
        Sounds.play(SoundNames.BTN_UI);
        setIsVisualEffectsOn(value)
        getEvent(EventType.ENABLE_TURBO_MODE).send(value);
    }, [setIsVisualEffectsOn])

    getEvent<boolean>(EventType.ENABLE_TURBO_MODE).subscribe(value => {
      setIsVisualEffectsOn(value);
    })

    getEvent<boolean>(EventType.DISABLE_TURBO_BTN).subscribe(value => {
      setDisableTurboBtn(value);
    })

    return (
        <PopoverWrapper>
            <Title>SETTINGS</Title>
            <List>
                <ListItem>
                    <ItemIcon>
                        <img src={iconAudio} alt='Audio' />
                    </ItemIcon>
                    <ItemBody>
                        <h2>AUDIO</h2>
                    </ItemBody>
                    <ButtonLabel style={isSoundOn ? {} : { background: '#535858' }}>
                        <ButtonInput
                            type='checkbox'
                            checked={isSoundOn}
                            onClick={event => changeAudioMode(event.currentTarget.checked)}
                        />
                        <ButtonSpan></ButtonSpan>
                    </ButtonLabel>
                </ListItem>

                <ListItem style={disableTurboBtn ? { opacity: 0.5 } : { opacity: 1 }}>
                    <ItemIcon>
                        <img src={iconVisualEffects} alt='Audio' />
                    </ItemIcon>
                    <ItemBody>
                        <h2>TURBO</h2>
                    </ItemBody>
                    <ButtonLabel
                        style={isVisualEffectsOn ? {} : { background: '#535858' }}
                    >
                        <ButtonInput
                            disabled={disableTurboBtn}
                            type='checkbox'
                            checked={isVisualEffectsOn}
                            onChange={event =>
                                changeEffects(event.currentTarget.checked)
                            }
                        />
                        <ButtonSpan></ButtonSpan>
                    </ButtonLabel>
                </ListItem>
            </List>
        </PopoverWrapper>
    );
};

export default Settings;

const PopoverWrapper = styled(Popover.Dropdown)`
  background: #151515;
  border-radius: 15px;
  border: 1px solid #2a2c2b;
  padding: 8px 32px 24px;
  color: #fff;
`;

const Title = styled.h2`
  text-align: center;
  color: #e2f4f2;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

const List = styled.div``;

const ListItem = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
`;

const ItemIcon = styled.div`
  height: 60px;
  width: 60px;
`;

const ItemBody = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
  }
`;

const ButtonInput = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ButtonLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 53px;
  padding: 4px;
  height: 29px;
  border-radius: 60px;
  background: conic-gradient(
          from 180deg at 50% 50%,
          #af70c1 0deg,
          #45d1bd 125.63deg,
          #f9ad29 266.25deg,
          #af70c1 360deg
  );
  input:checked + span::before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
    background-color: #e2f4f2;
  }
`;

const ButtonSpan = styled.span`
  position: absolute;
  cursor: pointer;
  border-radius: 60px;
  margin: 2px 2px 2px 2px;
  width: 49px;
  height: 25px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #151515;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  :before {
    position: absolute;
    content: '';
    height: 19px;
    width: 19px;
    left: 3px;
    bottom: 3px;
    background-color: #535858;
    -webkit-transition: 0.4s;
    border-radius: 50%;
    transition: 0.4s;
  }
`;
