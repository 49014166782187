import {
	ApiResponse,
	Cords,
	PlayResponse,
	SymbolName,
	WinEvent,
} from '../../api/types';
import { Container, Graphics, Sprite, Texture } from 'pixi.js';
import { Game } from '../Game';
import { Reel } from './Reel';
import { UI_ASSETS } from '../assetsLoader/UiAssets';
import { TEST_SYMBOLS } from '../constants/Symbols';
import { Timer } from '../../utils/Timer';
import { SYMBOL_SIZE } from '../constants/Symbols';
import { SymbolItem } from './SymbolItem';
import BaseAmount from '../components/BaseAmount/BaseAmount';
import { EventType, getEvent } from '../../GameEventBus';
import {Spine} from "pixi-spine";

export class SlowWin {
	winLines: WinEvent[];
	reels: Reel[];
	wins: number[];
	forbidden: boolean;
	reelsContainer: Container;
	game: Game;
	background: Sprite;
	board: SymbolName[][];
	response?: ApiResponse<PlayResponse>;
	timers: Timer[];
    stopAnimate: boolean;
	resultsCount: number;
	multiplier: number;

	constructor(game: Game) {
		this.game = game;
		this.winLines = [];
		this.reels = [];
		this.board = [];
		this.wins = [];
		this.forbidden = false;
		this.reelsContainer = new Container();
		this.background = new Sprite(Texture.from(UI_ASSETS.GAME_FIELD_BG));
		this.reelsContainer.visible = false;
		this.timers = [];
        this.stopAnimate = false;
		this.resultsCount = 0;
		this.multiplier = 1;
	}

	public init = async () => {
		this.setupContainer();
		this.setupBackground();
		this.game.resizeCallbacks.push(this.resize);
	};

    setWinLines = async (winLines: WinEvent[]) => {
        this.winLines = winLines;
    }

	private async prepareReels() {
		for (let i = 0; i < TEST_SYMBOLS.length; i++) {
			const symbols = TEST_SYMBOLS[i];
			const reel = new Reel(this.game, i, this.reelsContainer);
			reel.init();
			reel.generateReels(symbols);
			reel.placeSymbols(i);
			this.reels.push(reel);
		}

	}

	private setupContainer = () => {
		this.reelsContainer.width = this.game.assetsManager.gameField!.width;
		this.reelsContainer.height = this.game.assetsManager.gameField!.height;
		this.reelsContainer.name = 'SLOW_WIN_REELS_CONTAINER';
		this.reelsContainer.sortableChildren = true;
	};

	private setupBackground() {
		this.reelsContainer.zIndex = 300;
		this.reelsContainer.addChild(this.background);
	}

	public async prepareWinAmounts(response: ApiResponse<PlayResponse>, count: number) {
		if (this.winLines.length < 1) return;
		this.response = response;
		this.resultsCount = count;
		this.multiplier = this.response?.results[count].clientData.multiplier;
		this.board = response.results[count].clientData.reels.board;
		await this.prepareReels();
		await this.setupWinReels();
	}

	public async play(playAll: boolean) {
		if (this.winLines.length < 1 || this.forbidden) return;
		this.resize();
		const baseAmount = this.game.assetsManager.baseAmount as BaseAmount;

		const containerTimer = new Timer(() => {
			baseAmount.container.renderable = true;
			this.reelsContainer.visible = true;
			this.game.slotMachine?.showReels(false);
		}, 500);

		const overlayTimer = new Timer(() => {
			this.setupOverlay();
		}, 400);

		overlayTimer.initialize();

		containerTimer.initialize();
		this.timers.push(containerTimer);

		if (this.response) {
			this.response.results[this.resultsCount].clientData.reels.eventsLeft.forEach(event => {
				this.wins.push(event.win);
			});
		}

		this.stopAnimate = false;
		if (playAll && !this.stopAnimate) {
			await this.playAllWinlines(baseAmount, playAll);
			return;
		}

		while (!this.stopAnimate && !playAll) {
			getEvent<boolean>(EventType.SLOW_WIN_ANIMATION_OVER).subscribe(value => {
				this.stopAnimate = value;
				this.clearTimers();
				baseAmount.container.renderable = true;
			});
			if (this.stopAnimate) return;
			await this.sleep(400);
			for (let i = 0; i < this.winLines.length; i++) {
				this.winLines[i].coords.forEach(async cord => {
					if (this.stopAnimate) return;
                    if(!this.reels) return;
					let symbol: SymbolItem;
					symbol = this.reels[cord.x].symbols[3 - cord.y] as SymbolItem;
					if (symbol) await this.animate(symbol, cord, baseAmount, this.wins[i], playAll);
					await this.restoreOverlay();
				});
				await this.sleep(2000);
				if(i === this.winLines.length -1 && this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) {
					getEvent<boolean>(EventType.SLOW_WIN_ANIMATION_OVER).send(true);
				}
			}
			await this.sleep(400);
		}
	}

	private playAllWinlines = async (baseAmount: BaseAmount, playAll: boolean) => {
		await this.sleep(1000);
		if (this.stopAnimate) return;
		if (!this.response) return;
		if(!this.reels) return;
		let symbol: SymbolItem;
		const win = this.response?.results[this.resultsCount].cashWin / this.multiplier;
		this.winLines.forEach((event) => {
			const eventData = ("StickyWild" in event) ? event.StickyWild![0] : event;
			eventData.coords.forEach((cord) => {
				symbol = this.reels[cord.x].symbols[3 - cord.y] as SymbolItem;
				this.animate(symbol, cord, baseAmount, Number(win), playAll);
			});
		})
		await this.sleep(1500);
		await this.restoreOverlay();
		await Promise.all(this.winLines)
		if (this.game.slotMachine?.isFsRunning) {
			this.reelsContainer.visible = false;
			this.reset();
		}
	}

	private async restoreOverlay() {
		await this.sleep(750);
		this.reels.forEach(item => {
			item.container.children.forEach((child: any) => {
				const symbol = child as Container;
				symbol.children.forEach(child => {
					if (child.name === `overlay`) {
						child.visible = true;
						child.zIndex = 300;
					}
				});
			});
		});
		this.showOverlayForWild(true);
	}

    findChildByName(item: any) {
        return this.game.assetsManager.gameField?.container.children.find(child => child.name === item.name);
    }

    drawOverlay = (child: any, isWild: boolean) => {
        const symbol = child as Container;
        const overlay = new Graphics();
        overlay.name = `overlay`;
        overlay.beginFill(0x000000);
        overlay.drawRect(-4, -4, SYMBOL_SIZE + 7, SYMBOL_SIZE + 7);
        overlay.endFill();
        overlay.x = isWild ? 52 : 0;
        overlay.y = isWild ? 52 : 0;
        overlay.alpha = 0.33;
        overlay.zIndex = 100;
        symbol.addChild(overlay);
    }

    showOverlayForWild = (state: boolean) => {
        this.game.assetsManager.gameField?.tempWildSymbols.forEach(child => {
            const childContainer = this.findChildByName(child);
            if (childContainer) {
                child.children.forEach(item => {
                    if (item.name === `overlay`) {
                        item.visible = state;
                    }
                });
            }
        })
    }

	private async animate(
		symbol: SymbolItem,
		cord: Cords,
		baseAmount: BaseAmount,
		win: number,
		playAll: boolean
	) {
		return new Promise(async resolve => {
			this.game.slotMachine?.showReels(false);
			this.reels[cord.x].container.zIndex =
				symbol.name === 'WILD' ? 400 : cord.x;
			symbol.win(this.game, cord);

			await baseAmount.openSlow('USD', String(win.toFixed(2)), String(this.multiplier), playAll);
			symbol.container.children.forEach(item => {
				if (item.name === `overlay`) {
					item.visible = false;
				}
			});
			this.showOverlayForWild(false);
			if (playAll) {
				return resolve (true);
			} else {
				const timer = new Timer(() => {
					return resolve(true);
				}, 1000);
				timer.initialize();
				this.timers.push(timer);
			}
			this.game.assetsManager.playBtn!.changeButtonMode('default');
		});
	}

	public reset() {
		this.game.slotMachine?.showReels(true);
		this.reelsContainer.visible = false;
		this.reelsContainer.removeChildren();
		this.reelsContainer.addChild(this.background);
		this.winLines = [];
		this.wins = [];
		this.reels = [];
		this.forbidden = false;
	}

	async setupWinReels() {
		const reelRemap = (reel: Reel) => {
			const newSymbols = this.board[reel.id];
			reel.replaceSymbols(newSymbols);
			reel.onStartAnimate(false, false);
			reel.replaceSecondSymbols(newSymbols);
			reel.onEndAnimate();
			if (this.response?.results[this.resultsCount].clientData.reels.symbolEvents.length !== 0) reel.symbolToWild(newSymbols, this.response?.results[this.resultsCount].clientData.reels.symbolEvents!, false)
		};
		this.reels.map(reelRemap);
	}

	private setupOverlay() {
		this.reels.forEach(item => {
			item.container.children.forEach(child => {
				const symbol = child as Container;
				const overlay = new Graphics();
				overlay.name = `overlay`;
				overlay.beginFill(0x000000);
				overlay.drawRect(-4, -4, SYMBOL_SIZE + 7, SYMBOL_SIZE + 7);
				overlay.endFill();
				overlay.alpha = 0.33;
				overlay.zIndex = 100;
				symbol.addChild(overlay);
			});
		});
		this.game.assetsManager.gameField?.tempWildSymbols.forEach(child => {
			const childContainer = this.findChildByName(child);
			if (childContainer) this.drawOverlay(child, true)
		});
	}

	public sleep = async (ms: number) => {
		return new Promise(async resolve => {
			const timer = new Timer(() => {
				return resolve(true);
			}, ms);
			timer.initialize();
			this.timers.push(timer);
			getEvent<boolean>(EventType.SLOW_WIN_ANIMATION_OVER).subscribe(value => {
				this.clearTimers();
				return resolve(true);
			});
		});
	};

	private clearTimers() {
		this.timers.forEach(timer => {
			timer.clear();
		});
		this.timers = [];
	}

	public resize = () => {
		this.reelsContainer.position.set(0, 0);
	};
}
