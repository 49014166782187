import { Game } from "../Game";
import { GameField } from "../components/gameField/GameField";
import * as PIXI from 'pixi.js'
import { InfoBar } from "./InfoBar";
import PlayBtn from "../components/PlayBtn/PlayBtn";
import Multiplier from "../components/multiplier/Multiplier";
import AutoPlayButton from "../components/AutoPlayButton/AutoPlayButton";
import Logo from "../components/Logo/Logo";
import FooterForMobileVertical from "../components/FooterForMobileVertical/FooterForMobileVertical";
import AutoPlayModal from "../components/AutoPlayModal";
import ButtonInfo from "../components/ButtonInfo/ButtonInfo";
import BuyBonusButton from "../components/BuyBonusButton/BuyBonusButton";
import FooterForMobileHorizontal from "../components/FooterForMobileHorizontal/FooterForMobileHorizontal";
import MiniGame from "./miniGame";
import BurgerMenuBtn from "../components/BurgerMenuBtn/BurgerMenuBtn";
import BetBtn from "../components/BetButton/BetButton";
import { GameBackground } from "../components/GameBackground/GameBackground";
import BuyBonusConfirmModal from "../components/BuyBonusConfirmModal";
import { SpinEvents } from "./spinEvents";
import FreeSpinsSummary from "../components/FreeSpinsSummary/FreeSpinsSummary";
import WinFeatureModal from "../components/WinFeatureModal/WinFeatureModal";
import WinStartPopUp from "../components/WinStartPopUp";
import {FeatureDetailed} from "../../api/types";
import WinModal from "../components/WinModal/WinModal";
import RespinModal from "../components/RespinModal/RespinModal";
import { Preloader } from '../components/Preloader/Preloader';
import BaseAmount from '../components/BaseAmount/BaseAmount';
import Antisipation from "../components/Antisipation/Antisipation";
import MaxWinModal from '../components/MaxWinModal/MaxWinModal';
import { SlowWin } from "./slowWin";
import BuyBonusModal from "../components/BuyBonusModal";

export class assetsManager {
    game: Game
    gameField?: GameField
    gameContainer: PIXI.Container
    infoBar?: InfoBar
    playBtn?: PlayBtn
    multiplier?: Multiplier
    autoPlayBtn?: AutoPlayButton
    logo?: Logo
    footerForMobileVertical?: FooterForMobileVertical
    footerForMobileHorizontal?: FooterForMobileHorizontal
    miniGame?: MiniGame
    autoPlayModal?: AutoPlayModal
    btnInfo?: ButtonInfo
    buyBonusBtn?: BuyBonusButton
    burgerMenuBtn?: BurgerMenuBtn
    betBtn?: BetBtn
    gameBG?: GameBackground
    winModal?: WinModal
    winStartPopUp?: WinStartPopUp
    buyBonusConfirmModal?: BuyBonusConfirmModal
    spinEvents?: SpinEvents
    fss?: FreeSpinsSummary;
    winFeatureModal?: WinFeatureModal
    respinModal?: RespinModal;
    preloader?: Preloader;
    baseAmount?: BaseAmount;
    antisipation?: Antisipation; 
    maxWinModal?: MaxWinModal;
    slowWin?: SlowWin;
    buyBonusModal?: BuyBonusModal

    constructor(game: Game) {
        this.game = game
        this.gameContainer = new PIXI.Container()
        this.gameContainer.name = "GAME_CONTAINER"
        this.gameContainer.width = 1730
        this.gameContainer.height = 1010
        this.gameContainer.pivot.x = 960;
        this.gameContainer.sortableChildren = true
    }

    initPreloaderComponents = async () => {
        this.preloader = new Preloader(this.game)
        await this.preloader.init();
    }

    initAllComponents = async () => {
        this.gameBG = new GameBackground(this.game)
        await this.gameBG.init()

        this.baseAmount = new BaseAmount(this.game)
        await this.baseAmount.init();

        this.gameField = new GameField(this.game, this.baseAmount)
        await this.gameField.init()

        this.game.app.stage.addChild(this.gameContainer)
        this.infoBar = new InfoBar(this.game)
        await this.infoBar.init()

        this.playBtn = new PlayBtn(this.game)
        await this.playBtn.init()

        this.multiplier = new Multiplier(this.game)
        await this.multiplier.init()

        this.autoPlayBtn = new AutoPlayButton(this.game)
        await this.autoPlayBtn.init()

        this.logo = new Logo(this.game)
        await this.logo.init()

        this.footerForMobileVertical = new FooterForMobileVertical(this.game);
        await this.footerForMobileVertical.init();

        this.footerForMobileHorizontal = new FooterForMobileHorizontal(this.game);
        await this.footerForMobileHorizontal.init();

        this.buyBonusBtn = new BuyBonusButton(this.game);
        await this.buyBonusBtn.init();


        this.autoPlayModal = new AutoPlayModal(this.game)

        this.btnInfo = new ButtonInfo(this.game);
        await this.btnInfo.init();

        this.burgerMenuBtn = new BurgerMenuBtn(this.game)
        await this.burgerMenuBtn.init()

        this.betBtn = new BetBtn(this.game)
        await this.betBtn.init()

        this.miniGame = new MiniGame(
            this.game, this.gameField, this.playBtn,
            this.autoPlayBtn, this.infoBar, this.multiplier,
            this.burgerMenuBtn, this.betBtn, this.buyBonusBtn
        );

        await this.miniGame.init()

        this.winModal = new WinModal(this.game)
        await this.winModal.init()

        this.winStartPopUp = new WinStartPopUp(this.game);
        await this.winStartPopUp.init()
        this.buyBonusModal = new BuyBonusModal(this.game)
        await this.buyBonusModal.init()
        this.buyBonusConfirmModal = new BuyBonusConfirmModal(this.game)
        await this.buyBonusConfirmModal.init()
        this.spinEvents = new SpinEvents(this.game)
        this.spinEvents.init()

        this.fss = new FreeSpinsSummary(this.game);
        await this.fss.init()
        // await this.fss.showModal(true, 123)

        this.winFeatureModal = new WinFeatureModal(this.game);
        await this.winFeatureModal.init();

        this.respinModal = new RespinModal(this.game)
        await this.respinModal.init();

        this.antisipation = new Antisipation(this.game);
        await this.antisipation.init();

        this.maxWinModal = new MaxWinModal(this.game);
        await this.maxWinModal.init()
        this.slowWin = new SlowWin(this.game);
        await this.slowWin.init();
    }

    changeButtonsState = (status: boolean) => {
        this.playBtn?.changeButtonState(status)
    }

}
