import styled from "styled-components";
import BetList from "./components/BetList";
import MinMaxBet from "./components/MinMaxBet";
import { useState, useCallback, FC } from "react";
import { EventType, useEventReceiver, getEvent } from "../../../GameEventBus";
import { Game, ratio } from "../../../game/Game";
import { LocalStorage } from "../../../utils/localStorage";
import { SoundNames } from '../../../game/constants/sounds';
import { Sounds } from '../../../game/classes/SoundController';
import { respondFrom, respondTo } from '../../../styles/mixins';

interface IGame {
    game: Game
}

const MobileTotalBetModal = ({ game }: IGame) => {
    let betsArray: number[];
    let index: number;

    if (!game.slotMachine) {
        betsArray = [];
    } else {
        betsArray = game.slotMachine?.betList;
    }

    index = LocalStorage.getItem('chosenBet') ? LocalStorage.getItem('chosenBet') : 0;
    const [bet, setBet] = useState(betsArray[index > 0 ? index : 0]);
    const [isModalActive, setModalActive] = useState(false);
    const [ratioState, setRatio] = useState(window.innerWidth / window.innerHeight)

    window.addEventListener('resize', () => setRatio(window.innerWidth / window.innerHeight))

    const handleCloseModal = useCallback(() => {
        Sounds.play(SoundNames.BTN_UI);
        setModalActive(false);
    }, [setModalActive])

    useEventReceiver<boolean>(EventType.OPEN_TOTAL_BET_MOBILE, value => {
        setModalActive(value);
    })

    const handleBet = useCallback((index: number, bet: number) => {
        setModalActive(false);
        game.assetsManager.footerForMobileVertical?.setTotalBet(index, 'USD');
        game.assetsManager.footerForMobileHorizontal?.setTotalBet(index);
        game.slotMachine!.bet = bet;
    }, [setModalActive, game, getEvent])

    return (
        <Wrapper style={{ visibility: isModalActive ? 'visible' : 'hidden' }}>
            <Container
                style={{
                    bottom: ratioState <= 0.76 ? '0' : 'auto',
                    right: ratioState <= 0.76 ? 'auto' : '0'
                }}>
                <HeaderContainer>
                    <ButtonContainer onClick={handleCloseModal}>
                        {closeArrowDown}
                    </ButtonContainer>

                    <HeaderTextContainer>
                        <Header>
                            TOTAL BET
                        </Header>
                        <Desciption>
                            Choose your bet
                        </Desciption>
                    </HeaderTextContainer>

                    <BtnMiniContainer>
                        <BtnMiniBG>
                            <Button className="btn-hover"
                                onClick={() => handleBet(index, bet)}
                                value={bet}
                            >
                                {bet?.toFixed(2)} USD
                            </Button>
                        </BtnMiniBG>
                    </BtnMiniContainer>
                </HeaderContainer>
 
                <MinMaxBet game={game} />
                <BetList setBet={setBet} game={game} />

            </Container>
        </Wrapper>
    );
}

export default MobileTotalBetModal;

const Wrapper = styled.div`
  display: flex;
  padding: 38px 6px 6px;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(21, 21, 21, 0.8);
  left: 0;
  top: 0;
  align-items: flex-end;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: flex-start;
  justify-content: flex-end;
  ${respondFrom.sm`
		align-items: flex-end;
		justify-content: center;
	`}
`;


const Container = styled.div`
    padding: 9px;
    display: flex;
    flex-direction: column;
    width: 304px;
    height: 266px;
    border-radius: 15px;
    border: 1px solid #2A2C2B;
    background: #151515;
    color: #e4f2f4;
    ${respondFrom.sm`
		max-width: 360px;
		width: 100%;
	`}
`

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: "100%";
    height: 32px;
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
`
const HeaderTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: none;
`
const Header = styled.h5`
    font-family: Rubik;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
`
const Desciption = styled.p`
    font-family: Rubik;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #7C8584;

`
const BtnMiniContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 69px;
    height: 32px;
    margin-left: 5px;
`

const BtnMiniBG = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 69px;
    height: 32px;
    border-radius: 7px;
    background-size: 100% 100%;
    background-position: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-image: radial-gradient(49% 81% at 45% 47%, #FFE20345 0%, #073AFF00 100%),
    conic-gradient(from 180deg at 50% 50%, #af70c1 1%, #45d1bd 33%, #f9ad29 66%, #af70c1 100%),
    radial-gradient(142% 91% at 83% 7%, #FFDB00FF 1%, #FF000000 99%),
    radial-gradient(142% 91% at -6% 74%, #FF0049FF 1%, #FF000000 99%),
    radial-gradient(142% 91% at 111% 84%, #FF7000FF 0%, #FF0000FF 100%);
`

const Button = styled.button`
    background: #151515;  
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    width: 65px;
    height: 28px;
    border-radius: 7px;
    border: 2px solid #151515;
    color: #e4f2f4;  
    font-family: Rubik;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;

    &.btn-hover:hover {
        background: #e4f2f4;
        color: #151515;     
    }
    `

const closeArrowDown = <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.75L7.5 8.25L14 1.75" stroke="#E2F4F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>
