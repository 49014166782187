import {Container, Text, TextStyle} from "pixi.js";

class BuyBonusConfirmTextStyles {
    fontFamily: string;
    size: number;

    constructor() {
        this.fontFamily = "Norse-Bold";
        this.size = 40;
    }

    addText(text: string, fontSize: number) {
        this.size = fontSize;
        const textContainer = new Container();

        const underMain = new Text(text, this.textUnderMainFont());
        underMain.x = 4;
        underMain.y = -1.5;
        textContainer.addChild(underMain);

        const main = new Text(text, this.textMainFont());
        main.x = 5;
        main.y = 0;
        textContainer.addChild(main);
        return textContainer;
    }

    textUnderMainFont() {
        const color = "#ffe659";
        const style = new TextStyle({
            fill: color,
            fontFamily: this.fontFamily,
            fontSize: this.size,
            miterLimit: 2,
            letterSpacing: 0,
            padding: 5,
            stroke: "#ffe659",
            strokeThickness: 7,
        });
        return style;
    }

    textMainFont() {
        const gradient = ["#8c3b00", "#f68b00", "#f68b00", "#d27200", "#d27200"];
        const style = new TextStyle({
            fill: gradient,
            fontFamily: this.fontFamily,
            fontSize: this.size,
            miterLimit: 2,
            letterSpacing: 0,
            padding: 5,
            stroke: "#3c1100",
            strokeThickness: 4,
        });
        return style;
    }
}

export const buyBonusConfirmTextStyles = new BuyBonusConfirmTextStyles();
