import {
    Button,
    ButtonProps,
    Checkbox,
    createPolymorphicComponent,
    Flex,
    Select,
    TextInput,
} from '@mantine/core';
import {useForm} from '@mantine/form';
import {FC, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

const DebugPlayModal: FC = ()  => {
    const [isModalActive, setModalActive] = useState(false)
    const setDebugReels = useCallback((v: any) => {
        localStorage.setItem('debugReels', JSON.stringify(v));
        setModalActive(false)
    }, [setModalActive])

    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if (e.code === "KeyE") {
                setModalActive(!isModalActive)
            }
        }
        document.addEventListener('keydown', handler)
        return () => document.removeEventListener('keydown', handler)
    })


    const cleanDebugReels = () => {
        localStorage.removeItem('debugReels');
    };

    const form = useForm({
        initialValues: {
            positions: '',
            feature: '',
            command: '',
            maxWinCap: false,
        },
        validate: {
            positions: value => (value.length < 1 ? 'Cannot be empty' : null),
            command: value => (value.length < 1 ? 'Cannot be empty' : null),
            feature: value => (value.length < 1 ? 'Cannot be empty' : null),
        },
    });

    return (
        <Overlay style={{display: `${isModalActive ? 'flex' : 'none'}`}}>
            <Form onSubmit={form.onSubmit(setDebugReels)}>
                <FormTitle>Try your luck</FormTitle>
                <FormInput placeholder='Positions' {...form.getInputProps('positions')} />
                
                <FormTitle>Choose command</FormTitle>
                <Select
                  placeholder='Pick one'
                  {...form.getInputProps('command')}
                  data={[
                    { value: 'debug', label: 'debug' },
                    { value: 'buybonusspin', label: 'buybonusspin' },
                    { value: 'minigame', label: 'minigame' },
                    { value: 'buyextrafeature', label: 'buyextrafeature' },
                    { value: 'freegame', label: 'freegame' },
                  ]}
                />

                <Flex pt={20} align={'center'}>
                <Checkbox {...form.getInputProps('maxWinCap')} />
                <FormTitle style={{paddingLeft: 10, paddingTop: 10}}>Test max win cap</FormTitle>
                </Flex>

                <FormTitle>Choose feature</FormTitle>
                <Select
                  placeholder='Pick one'
                  {...form.getInputProps('feature')}
                  data={[
                    { value: '1', label: 'Symbol Remove' },
                    { value: '2', label: 'Symbol Wild' },
                    { value: '3', label: 'Multiplier Increase' },
                    { value: '4', label: 'Extra Freespins' },
                    { value: '5', label: 'Increase Multiplier' },
                    { value: '6', label: 'Sticky Wild' },
                    { value: '8', label: 'Minigame after respin' },
                  ]}
                />
                <ButtonsWrapper>
                    <SubmitButton type='submit' mr='sm'>
                        Save
                    </SubmitButton>
                    <SubmitButton onClick={cleanDebugReels}>Remove</SubmitButton>
                </ButtonsWrapper>
            </Form>
        </Overlay>
    );
};

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000080;
  width: 100%;
  height: 100%;
`


const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 450px;
  padding: 25px;
  border-radius: 4px;
  background: #000;
`;

const FormTitle = styled.h2`
  font-family: 'Rubik', sans-serif;
  text-align: center;
  padding-bottom: 10px;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
`;

const FormInput = styled(TextInput)`
  margin-bottom: 20px;
  position: relative;
  flex: auto;

  .mantine-InputWrapper-error {
    font-family: 'Rubik', sans-serif;
    position: absolute;
    bottom: 2;
    left: 0;
  }

  input {
    font-family: 'Rubik', sans-serif;
    padding: 0;
    border: none;
    border-radius: 0;
    background: transparent;
    border-bottom: 1px solid #ced4da;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
`;

const _SubmitButton = styled(Button)`
  font-family: 'Rubik', sans-serif;
  width: 100%;
`;

const SubmitButton = createPolymorphicComponent<'button', ButtonProps>(
    _SubmitButton
);


export default DebugPlayModal;
