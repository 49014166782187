import { FC } from 'react';
import styled from 'styled-components';
import { respondHeightFrom } from '../../../styles/mixins';
import MultiplierIncrease5 from '../../../game/assets/minigame/features/MultiplierIncrease5.png';
import SymbolWildH1 from '../../../game/assets/minigame/features/SymbolWildH1.png';
import SymbolRemoveL1 from '../../../game/assets/minigame/features/SymbolRemoveL1.png';
import IncreaseMultipliertrue from '../../../game/assets/minigame/features/IncreaseMultipliertrue.png';
import ExtraFreespins1 from '../../../game/assets/minigame/features/ExtraFreespins1.png';
import StickyWildtrue from '../../../game/assets/minigame/features/StickyWildtrue.png';
import minigame from '../../../assets/img/menu-modal/minigame.png';

const PaytableThirdPage: FC = () => {
	return (
		<>
			<Title>FREE SPINS</Title>
			<ReelsText style={{ flexDirection: 'column' }}>
				Start with a mini-game where you pick chests that contain random boosts
				for your free spins session.
				<img
					style={{ maxHeight: '140px', marginBottom: '10px' }}
					src={minigame}
					alt='Free Spins'
				/>
			</ReelsText>

			<ReelsContainer style={{ alignItems: 'flex-start' }}>
				<ReelsText>The available boosts are:</ReelsText>

				<ReelsText>
					<img src={MultiplierIncrease5} alt='Free Spins' /> a win multiplier of
					x2, x3 or x5
				</ReelsText>
				<ReelsText>
					<img src={SymbolWildH1} alt='Free Spins' /> one of the symbol types
					turns into wild (can be awarded multiple times)
				</ReelsText>
				<ReelsText>
					<img src={SymbolRemoveL1} alt='Free Spins' /> one of the low pays is
					removed from the reels (can be awarded multiple times)
				</ReelsText>
				<ReelsText>
					<img src={IncreaseMultipliertrue} alt='Free Spins' /> 1, 2 or 3 extra
					spins
				</ReelsText>
				<ReelsText>
					<img src={StickyWildtrue} alt='Free Spins' /> All wilds are sticky for
					the whole free spins session
				</ReelsText>
				<ReelsText>
					<img src={ExtraFreespins1} alt='Free Spins' /> Multiplier Increases by
					1 with every winning spins
				</ReelsText>
			</ReelsContainer>
		</>
	);
};

export default PaytableThirdPage;

const Title = styled.h2`
	text-align: center;
	color: #fff;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	padding-bottom: 20px;
	${respondHeightFrom.base`
		padding-bottom: 0;
	`}
`;

const ReelsContainer = styled.div`
	flex-direction: column;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
`;

const ReelsText = styled.div`
	padding: 5px;
	display: flex;
	font-weight: 700;
	font-size: 18px;
	align-items: center;
	justify-content: center;
	img {
		margin: 0 10px;
		max-height: 70px;
		align-self: center;
	}
`;
