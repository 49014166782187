import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import {
	respondFrom,
	respondTo,
	respondHeightFrom,
} from '../../../styles/mixins';
import SettingsModal from '../SettingsModal/SettingsModal';
import ExitModal from '../ExitModal/ExitModal';
import RulesModal from '../RulesModal/RulesModal';
import PaytableModal from '../PaytableModal/PaytableModal';
import { Game } from '../../../game/Game';

import iconExit from '../../../assets/icons/exit-icon.svg';
import iconRules from '../../../assets/icons/question-icon.svg';
import iconPaytable from '../../../assets/icons/info-icon.svg';
import iconSettings from '../../../assets/icons/settings-icon.svg';
import iconExitHover from '../../../assets/icons/exit-icon-hover.svg';
import iconRulesHover from '../../../assets/icons/question-icon-hover.svg';
import iconPaytableHover from '../../../assets/icons/info-icon-hover.svg';
import iconSettingsHover from '../../../assets/icons/settings-icon-hover.svg';
import { Sounds } from '../../../game/classes/SoundController';
import { SoundNames } from '../../../game/constants/sounds';

export enum MenuList {
	Paytable = 'Paytable',
	Settings = 'Settings',
	Rules = 'Rules',
	Exit = 'Exit',
}

interface IMenuModal {
	game: Game;
	activeModal: MenuList;
	setActiveModal: Function;
	isModalOpen: boolean;
	showMenuModal: () => void;
}

const MenuModal: FC<IMenuModal> = ({
	game,
	activeModal,
	setActiveModal,
	isModalOpen,
	showMenuModal,
}) => {
	const showActiveModal = (item: MenuList) => {
		Sounds.play(SoundNames.BTN_UI);
		game.assetsManager?.burgerMenuBtn?.changeMenuState(isModalOpen)
		setActiveModal(item);
	};

	const handleCloseModal = useCallback(() => {
		Sounds.play(SoundNames.BTN_UI);
		showMenuModal();
	}, [showMenuModal]);

	const renderMenuList = () => {
		switch (activeModal) {
			case MenuList.Paytable:
				return (
					<PaytableModal game={game} handleCloseModal={handleCloseModal} />
				);
			case MenuList.Settings:
				return <SettingsModal handleCloseModal={handleCloseModal} />;
			case MenuList.Rules:
				return <RulesModal game={game} handleCloseModal={handleCloseModal} />;
			case MenuList.Exit:
				return <ExitModal handleCloseModal={handleCloseModal} />;
		}
	};

	const menuList = [
		{
			img: iconPaytable,
			imgHover: iconPaytableHover,
			name: MenuList.Paytable,
		},
		{
			img: iconSettings,
			imgHover: iconSettingsHover,
			name: MenuList.Settings,
		},
		{
			img: iconRules,
			imgHover: iconRulesHover,
			name: MenuList.Rules,
		},
		{
			img: iconExit,
			imgHover: iconExitHover,
			name: MenuList.Exit,
		},
	];

	return (
		<Wrapper style={isModalOpen ? { display: 'block' } : { display: 'none' }}>
			<Container>
				<ModalContainer>{renderMenuList()}</ModalContainer>
				<Nav>
					{menuList.map(item => (
						<NavButton
							key={item.img}
							onClick={() => showActiveModal(item.name)}
						>
							<NavButtonBorder>
								<NavButtonBody
									className={item.name === activeModal ? 'nav-btn-hover' : ''}
								>
									<img src={item.img} alt={item.name} draggable={false} />
									<img src={item.imgHover} alt={item.name} draggable={false} />
								</NavButtonBody>
							</NavButtonBorder>
						</NavButton>
					))}
				</Nav>
			</Container>
		</Wrapper>
	);
};

export default MenuModal;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	background: rgba(21, 21, 21, 0.8);
	left: 0;
	top: 0;
	z-index: 10000;
`;

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	padding: 38px 8px 8px;
	width: 100%;
	height: 100%;
	min-height: 200px;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	${respondFrom.sm`
	 	flex-direction: column;
		height: 100%;
	`}
	${respondTo.lg`
		padding: 55px 15px 15px;
	`}
`;

const Nav = styled.nav`
	flex-direction: column;
	justify-content: space-around;
	padding-right: 6px;
	display: none;
	${respondFrom.sm`
		min-height: 70px;
		flex-direction: row;
		padding: 8px 0 10px;
	`}
	${respondFrom.lg`
		display: flex;
	`}
`;

const NavButton = styled.div`
	border-radius: 50%;
	height: 60px;
	width: 60px;
	background: rgba(21, 21, 21, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	img {
		display: inline-block;
	}
	img:last-child {
		display: none;
	}
	${respondHeightFrom.base`
		scale: 0.8;
		height: 48px;
	`}
	${respondHeightFrom.baseMin`
		scale: 0.7;
		height: 42px;
	`}
`;

const NavButtonBorder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	height: 52px;
	width: 52px;
	background: conic-gradient(
		from 180deg at 50% 50%,
		#af70c1 0deg,
		#45d1bd 125.63deg,
		#f9ad29 266.25deg,
		#af70c1 360deg
	);
`;

const NavButtonBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	width: 48px;
	border-radius: 50%;
	background: #151515;
	&.nav-btn-hover {
		background: #e2f4f2;
		border: 2px solid rgba(21, 21, 21, 0.8);
		img {
			display: none;
		}
		img:last-child {
			transition: all ease 0.5s;
			display: inline-block;
		}
	}
`;

const ModalContainer = styled.div`
	position: relative;
	flex: auto;
	height: 100%;
	${respondFrom.sm`
		height: 80%;
	`}
`;
