import {Loader} from "pixi.js";
import arrowDownIcon from '../assets/modal/auto-play-modal-arrow-small.svg'
import autoPLayStatus from '../assets/modal/auto-play-status.png'
import bg from "../assets/free_spins_summary/free_spins_summary_bg.png";
import youWon from "../assets/free_spins_summary/you_won.png"
import winStartModalText from "../assets/modal/win-start-modal-text.png";
import respin from "../assets/modal/respin.png"
import maxWinCap from "../assets/modal/max_win_cap.png"
import {CDN_BASE_URL} from "../../index";
import buyBonusModalBg from '../assets/buy_bonus_modal/buy_bonus_modal_bg.png'
import buyBonusModalBuy from '../assets/buy_bonus_modal/buy_bonus_modal_buy.png'
import buyBonusModalClose from '../assets/buy_bonus_modal/buy_bonus_modal_close.png'
import buyBonusModalConfirm from '../assets/buy_bonus_modal/buy_bonus_modal_confirm.png'
import buyBonusModalInput from '../assets/buy_bonus_modal/buy_bonus_modal_input.png'
import buyBonusModalMinus from '../assets/buy_bonus_modal/buy_bonus_modal_minus.png'
import buyBonusModalNo from '../assets/buy_bonus_modal/buy_bonus_modal_no.png'
import buyBonusModalPlus from '../assets/buy_bonus_modal/buy_bonus_modal_plus.png'
import buyBonusModalScatters from '../assets/buy_bonus_modal/buy_bonus_modal_scatters.png'
import buyBonusModalTitle from '../assets/buy_bonus_modal/buy_bonus_modal_title.png'
import buyBonusModalYes from '../assets/buy_bonus_modal/buy_bonus_modal_yes.png'

export enum MODAL_ASSETS {
    ARROW_DOWN_ICON = 'ARROW_DOWN_ICON',
    STATUS_ICON = 'STATUS_ICON',
    WIN_START_MODAL_TEXT = "WIN_START_MODAL_TEXT",
    MODAL_BACKGROUND = "MODAL_BACKGROUND",
    YOU_WON = "YOU_WON",
    BIG_WIN = "BIG_WIN",
    MEGA_WIN = "MEGA_WIN",
    SUPER_WIN = "SUPER_WIN",
    BW_SHEET = "BW_SHEET",
    RESPIN = "RESPIN",
    COIN_SHOWER = "COIN_SHOWER",
    MAX_WIN_CAP_TEXT = "MAX_WIN_CAP_TEXT",
    BUY_BONUS_MODAL_BG = "BUY_BONUS_MODAL_BG",
    BUY_BONUS_MODAL_BUY = "BUY_BONUS_MODAL_BUY",
    BUY_BONUS_MODAL_CLOSE = "BUY_BONUS_MODAL_CLOSE",
    BUY_BONUS_MODAL_CONFIRM = "BUY_BONUS_MODAL_CONFIRM",
    BUY_BONUS_MODAL_INPUT = "BUY_BONUS_MODAL_INPUT",
    BUY_BONUS_MODAL_MINUS = "BUY_BONUS_MODAL_MINUS",
    BUY_BONUS_MODAL_NO = "BUY_BONUS_MODAL_NO",
    BUY_BONUS_MODAL_PLUS = "BUY_BONUS_MODAL_PLUS",
    BUY_BONUS_MODAL_SCATTERS = "BUY_BONUS_MODAL_SCATTERS",
    BUY_BONUS_MODAL_TITLE = "BUY_BONUS_MODAL_TITLE",
    BUY_BONUS_MODAL_YES = "BUY_BONUS_MODAL_YES",
}

export const loadModalAssets = (loader: Loader) => {
    loader.add(MODAL_ASSETS.ARROW_DOWN_ICON, arrowDownIcon)
    loader.add(MODAL_ASSETS.STATUS_ICON, autoPLayStatus)
    loader.add(MODAL_ASSETS.MODAL_BACKGROUND, bg)
    loader.add(MODAL_ASSETS.YOU_WON, youWon)
    loader.add(MODAL_ASSETS.WIN_START_MODAL_TEXT, winStartModalText)
    loader.add(MODAL_ASSETS.BIG_WIN, `${CDN_BASE_URL}/spine/big_win/Big_Win_anim.json`)
    loader.add(MODAL_ASSETS.MEGA_WIN, `${CDN_BASE_URL}/spine/mega_win/Mega_Win_anim.json`)
    loader.add(MODAL_ASSETS.SUPER_WIN, `${CDN_BASE_URL}/spine/super_win/Super_Win_anim.json`)
    loader.add(MODAL_ASSETS.RESPIN, respin)
    loader.add(MODAL_ASSETS.BW_SHEET, `${CDN_BASE_URL}/bw/bw_numbers.json`)
    loader.add(MODAL_ASSETS.COIN_SHOWER, `${CDN_BASE_URL}/spine/coin_showers/Viking_coin_shower.json`)
    loader.add(MODAL_ASSETS.MAX_WIN_CAP_TEXT, maxWinCap)
    loader.add(MODAL_ASSETS.BUY_BONUS_MODAL_BG, buyBonusModalBg)
    loader.add(MODAL_ASSETS.BUY_BONUS_MODAL_BUY, buyBonusModalBuy)
    loader.add(MODAL_ASSETS.BUY_BONUS_MODAL_CLOSE, buyBonusModalClose)
    loader.add(MODAL_ASSETS.BUY_BONUS_MODAL_CONFIRM, buyBonusModalConfirm)
    loader.add(MODAL_ASSETS.BUY_BONUS_MODAL_INPUT, buyBonusModalInput)
    loader.add(MODAL_ASSETS.BUY_BONUS_MODAL_MINUS, buyBonusModalMinus)
    loader.add(MODAL_ASSETS.BUY_BONUS_MODAL_NO, buyBonusModalNo)
    loader.add(MODAL_ASSETS.BUY_BONUS_MODAL_PLUS, buyBonusModalPlus)
    loader.add(MODAL_ASSETS.BUY_BONUS_MODAL_SCATTERS, buyBonusModalScatters)
    loader.add(MODAL_ASSETS.BUY_BONUS_MODAL_TITLE, buyBonusModalTitle)
    loader.add(MODAL_ASSETS.BUY_BONUS_MODAL_YES, buyBonusModalYes)
}
