import { FC } from 'react';
import styled from 'styled-components';
import { respondFrom } from '../../../styles/mixins';
import SliderButton from './PaytableSliderButton';
import { Sounds } from '../../../game/classes/SoundController';
import { SoundNames } from '../../../game/constants/sounds';
import PaytableFirstPage from './PaytableFirstPage';
import paytableItem2 from '../../../assets/img/menu-modal/paytable_page_2.png';
import paytableItem3 from '../../../assets/img/menu-modal/paytable_page_3.png';
import PaytableFourthPage from './PaytableFourthPage';

interface ISlider {
	slideIndex: number;
	setSlideIndex: Function;
	fourthFeaturePrice: number;
	fifthFeaturePrice: number;
}

const PaytableSlider: FC<ISlider> = ({
	slideIndex,
	setSlideIndex,
	fourthFeaturePrice,
	fifthFeaturePrice,
}) => {
	const nextSlide = () => {
		Sounds.play(SoundNames.BTN_UI);
		if (slideIndex !== slidesList.length) {
			setSlideIndex(slideIndex + 1);
		} else if (slideIndex === slidesList.length) {
			setSlideIndex(1);
		}
	};

	const slidesList = [
		{ component: <PaytableFirstPage /> },
		{
			component: (
				<img
					style={{ maxWidth: '100%', maxHeight: '100%' }}
					src={paytableItem2}
					alt=''
				/>
			),
		},
		{
			component: (
				<img
					style={{ maxWidth: '100%', maxHeight: '100%' }}
					src={paytableItem3}
					alt=''
				/>
			),
		},
		{
			component: (
				<PaytableFourthPage
					fourthFeaturePrice={fourthFeaturePrice}
					fifthFeaturePrice={fifthFeaturePrice}
				/>
			),
		},
	];

	const prevSlide = () => {
		Sounds.play(SoundNames.BTN_UI);
		if (slideIndex !== 1) {
			setSlideIndex(slideIndex - 1);
		} else if (slideIndex === 1) {
			setSlideIndex(slidesList.length);
		}
	};

	const moveDot = (index: number) => {
		setSlideIndex(index);
	};

	return (
		<>
			<SlidesContainer>
				{slidesList.map((item, index) => (
					<Slide
						className={slideIndex === index + 1 ? 'slide active-anim' : 'slide'}
						key={index}
					>
						<PageWrapper>{item.component}</PageWrapper>
					</Slide>
				))}
			</SlidesContainer>
			<Nav>
				<SliderButton changeSlide={prevSlide} direction={'prev'} />
				{Array.from({ length: slidesList.length }).map((item, index) => (
					<NavDot onClick={() => moveDot(index + 1)} key={index}>
						<NavDotBody
							className={slideIndex === index + 1 ? 'active' : ''}
						></NavDotBody>
					</NavDot>
				))}
				<SliderButton changeSlide={nextSlide} direction={'next'} />
			</Nav>
		</>
	);
};

export default PaytableSlider;

const SlidesContainer = styled.div`
	.active-anim {
		opacity: 1;
		display: flex;
	}
`;

const Slide = styled.div`
	padding: 30px 0px 100px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	display: none;
	transition: opacity ease-in-out 0.4s;
`;

const Nav = styled.nav`
	position: absolute;
	bottom: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
	padding-right: 6px;
	${respondFrom.sm`
		min-height: 70px;
	 	flex-direction: row;
		padding: 8px 0 10px;
	`}
`;

const NavDot = styled.div`
	border-radius: 50%;
	height: 60px;
	width: 60px;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	pointer-events: none;
	.active {
		background: conic-gradient(
			from 180deg at 50% 50%,
			#af70c1 0deg,
			#45d1bd 125.63deg,
			#f9ad29 266.25deg,
			#af70c1 360deg
		);
	}
`;

const NavDotBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background: #2a2c2b;
`;

const PageWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
`;
