import { Game } from "../../Game";
import { UI_ASSETS } from "../../assetsLoader/UiAssets";
import {Spine} from "pixi-spine";

export class GameBackground {
    game: Game
    gameBG?: Spine
    freeGameBG?: Spine
    constructor(game: Game) {
        this.game = game
    }

    init = () => {
        const spineData = this.game.app.loader.resources[UI_ASSETS.GAME_BACKGROUND].spineData!
        const spineDataFS = this.game.app.loader.resources[UI_ASSETS.FS_BACKGROUND].spineData!
        this.gameBG = new Spine(spineData)
        this.gameBG!.width = 1920
        this.gameBG!.height = 1920
        this.gameBG.state.setAnimation(0, 'animation3', true)

        this.freeGameBG = new Spine(spineDataFS)
        this.freeGameBG!.width = 1923
        this.freeGameBG!.height = 1921
        this.freeGameBG!.renderable = false;
        this.freeGameBG.state.setAnimation(0, 'animation', true)

        this.game.app.stage.addChild(this.gameBG)
        this.game.app.stage.addChild(this.freeGameBG)

        this.game.resizeCallbacks.push(this.resize)
    }

    /**
     * function for adaptive description
     */

    resize = () => {
        if (!this.gameBG) return
        if (!this.freeGameBG) return

        // this.gameBG.anchor.set(0.5, 0.5)
        this.gameBG.x = window.innerWidth / 2
        this.gameBG.y = window.innerHeight / 2

        // this.freeGameBG.anchor.set(0.5, 0.5)
        this.freeGameBG.x = window.innerWidth / 2
        this.freeGameBG.y = window.innerHeight / 2

        const currentRatio = window.innerWidth / window.innerHeight
        const baseRatio = 1920 / 1080


        if (baseRatio <= currentRatio) {
            this.gameBG.scale.set(window.innerWidth / 1920, window.innerWidth / 1920)
            this.freeGameBG.scale.set(window.innerWidth / 1920, window.innerWidth / 1920)
        } else {

            if (window.innerHeight / 1920 <= baseRatio) {
                if (window.innerWidth > window.innerHeight) {
                    this.gameBG.scale.set(window.innerWidth / 1920, window.innerWidth / 1920)
                    this.freeGameBG.scale.set(window.innerWidth / 1920, window.innerWidth / 1920)
                } else {
                    this.gameBG.scale.set(window.innerHeight / 1920, window.innerHeight / 1920)
                    this.freeGameBG.scale.set(window.innerHeight / 1920, window.innerHeight / 1920)
                }
            } else {
                this.gameBG.scale.set((window.innerHeight / 1920), (window.innerHeight / 1920))
                this.freeGameBG.scale.set((window.innerHeight / 1920), (window.innerHeight / 1920))
            }
            if (currentRatio <= 0.6) {
                this.gameBG.y = window.innerHeight / 2 - 30
                this.freeGameBG.y = window.innerHeight / 2 - 30
            }
        }

    }
}
