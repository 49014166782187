import { Loader } from 'pixi.js';
import progressBarBG from '../assets/loader/bottom_loading_bar.png';
import progressBar from '../assets/loader/violet_loading.png';
import progressBarMask from '../assets/loader/violet_loading_mask.png';
import loadingText from '../assets/loader/loading-text.png';
import background from '../assets/loader/preloader_bg.png';
import {CDN_BASE_URL} from "../../index";
import popup from '../assets/loader/splash-screen-popup.png';

export enum PRELOADER_ASSETS {
	BACKGROUND = 'BACKGROUND',
	LOGO = 'LOGO',
	PROGRESS_BAR_BG = 'PROGRESS_BAR_BG',
	PROGRESS_BAR = 'PROGRESS_BAR',
	PROGRESS_BAR_MASK = 'PROGRESS_BAR_MASK',
	LOADING_TEXT = 'LOADING_TEXT',
	POPUP = 'POPUP',
}

export const preloaderAssets = (loader: Loader) => {
	loader.add(PRELOADER_ASSETS.BACKGROUND, background);
	loader.add(PRELOADER_ASSETS.PROGRESS_BAR_BG, progressBarBG);
	loader.add(PRELOADER_ASSETS.PROGRESS_BAR, progressBar);
	loader.add(PRELOADER_ASSETS.PROGRESS_BAR_MASK, progressBarMask);
	loader.add(PRELOADER_ASSETS.LOADING_TEXT, loadingText);
	loader.add(PRELOADER_ASSETS.POPUP, popup);
	loader.add(
		PRELOADER_ASSETS.LOGO,
		`${CDN_BASE_URL}/spine/splash_screen/Splash_screen_desktop (1).json`
	);
};
