import { FC } from 'react';
import styled from 'styled-components';
import { respondHeightFrom } from '../../../styles/mixins';

interface IProps {
	RTP: string;
	fourthFeatureRTP: string;
	fifthFeatureRTP: string;
}

const RulesFourthPage: FC<IProps> = ({
	RTP,
	fourthFeatureRTP,
	fifthFeatureRTP,
}) => {
	return (
		<Container>
			<Title>Payouts:</Title>
			<Text>
				Wins are awarded for identical symbols landing on 3 or more consecutive
				reels, starting from the leftmost one.{' '}
			</Text>
			<Text>
				Wins are calculated according to the paytable, multiplied by the number
				of winning ways for each symbol.{' '}
			</Text>
			<Text>
				Wild symbol substitutes for any symbol except the bonus or chest
				symbols.
			</Text>

			<br />
			<br />

			<Title>How to play:</Title>
			<Text>Use the '-' and '+' buttons to adjust the bet size.</Text>
			<Text>Click the spin button or press 'space' to make a bet.</Text>
			<Text>Enable turbo in setting to speed up the game.</Text>
			<Text>
				Autoplay launches the game for a selected number of bets at the current
				stake.
			</Text>

			<br />
			<br />

			<Title>Additional information:</Title>
			<Text>The RTP of the main game and buy bonus is {RTP}%.</Text>
			<Text>The RTP of buying the 4th boost pick is {fourthFeatureRTP}%.</Text>
			<Text>The RTP of buying the 5th boost pick is {fifthFeatureRTP}%.</Text>
			<Text>Malfunction voids all pays and plays. </Text>
			<Text>Max win is capped at 20 000 x bet.</Text>
		</Container>
	);
};

export default RulesFourthPage;

const Title = styled.h2`
	color: #fff;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	padding-bottom: 20px;
	${respondHeightFrom.base`
		padding-bottom: 0;
	`}
`;

const Container = styled.div`
	flex-direction: column;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
`;

const Text = styled.div`
	padding: 2px;
	color: #fff;
	display: flex;
	font-weight: 700;
	font-size: 18px;
`;
