
import { Game } from '../../Game'
import {EventType, getEvent} from "../../../GameEventBus";
import { MODAL_ASSETS } from '../../assetsLoader/modalLoader'
import { Container, Graphics, Sprite, Text, Texture } from 'pixi.js';
import { buyBonusTextStyles } from '../BuyBonusModal/buyBonusTextStyles';

class BuyBonusConfirmModal {
	game: Game
	modalWrapper: Container;
	windowBackground: Graphics;
	modal: Sprite;
	background: Sprite;
	title: Sprite;
	image: Sprite;
	option: Container;
	subTitle: Container;
	closeButton: Sprite;
	yesButton: Sprite;
	noButton: Sprite;
	isModalOpen: boolean
	isBuyBonus: boolean;

	constructor(game: Game) {
		this.game = game
		this.isModalOpen = false
		this.modal = new Sprite();
		this.modalWrapper = new Container();
		this.background = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_BG));
		this.closeButton = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_CLOSE));
		this.yesButton = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_YES));
		this.noButton = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_NO));
		this.windowBackground = new Graphics();
		this.title = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_CONFIRM));
		this.image = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_SCATTERS));
		this.option = buyBonusTextStyles.addText('3-5 FREE SPINS', 42);
		this.subTitle = buyBonusTextStyles.addText('', 34);
		this.isBuyBonus = false;
	}

	init = async () => {
		await this.setupButtons();
		this.modalWrapper.position.set(0, 0);
		this.modalWrapper.width = window.innerWidth
		this.modalWrapper.height = window.innerHeight
		this.modalWrapper.visible = false
		this.modalWrapper.zIndex = 10000

		this.windowBackground.beginFill(0x151515)
		this.windowBackground.drawRect(0, 0, window.innerWidth, window.innerHeight);
		this.windowBackground.endFill()
		this.windowBackground.alpha = 0.8

		this.background.anchor.set(0.5);
		this.windowBackground.zIndex = 1
		this.modal.zIndex = 15;
		this.background.width = 987;
		this.background.height = 686;

		this.modal.anchor.set(0.5);
		this.image.anchor.set(0.5);
		this.title.anchor.set(0.5);
		
		this.title.position.set(0, -this.title.height - 30);
		this.image.position.set(0, this.image.height / 2 - 60);
		this.option.position.set(-this.option.width / 2, -102);
		this.subTitle.position.set(-this.subTitle.width / 2, 190);

		this.game.app.stage.addChild(this.modalWrapper)
		this.modalWrapper.addChild(this.windowBackground);
		this.modalWrapper.addChild(this.modal);
		this.modal.addChild(this.background);
		this.background.addChild(this.title);
		this.background.addChild(this.image);
		this.background.addChild(this.option);
		this.background.addChild(this.subTitle);
		this.game.resizeCallbacks.push(this.resize)
	};

	handleClick = async () => {
		if (this.game.slotMachine) {
			if(this.game.slotMachine!.bet * 100 > this.game.slotMachine.balance) {
				let errorArray = ["Not enough funds", "Please refill your balance", '']
				getEvent(EventType.ERROR).send(errorArray);
				return
			}
			this.isBuyBonus = true;
			await this.game.slotMachine.checkIsBuyBonus(true);
			this.game.slotMachine.changeBalance(-(this.game.slotMachine!.bet * 100));
			this.game.slotMachine.nextCommand = 'buybonusspin'
			this.showModal(false)
			await this.game.slotMachine.onSpin('buybonusspin')
		} else {
			this.showModal(false)
		}
	}
	showModal = (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true
			this.game.assetsManager.gameContainer.interactiveChildren = false
			const currency = this.game.initData.account.currency ? this.game.initData.account.currency : "USD"
			this.changeText(this.subTitle, `${this.game.slotMachine!.bet * 100} ${currency}`);
		} else {
			this.modalWrapper.visible = false
			this.game.assetsManager.gameContainer.interactiveChildren = true
		}
		this.resize()
	};

	setupButtons = async () => {
		this.yesButton.interactive = true;
		this.yesButton.buttonMode = true;
		this.yesButton.on('touchstart', () => this.handleClick())
		this.yesButton.on('click', (e) => this.leftClick(e))
		this.yesButton.anchor.set(0.5);
		this.yesButton.position.set(-this.yesButton.width * 1.4, this.background.height / 2 - 110);
		this.background.addChild(this.yesButton)

		this.noButton.interactive = true;
		this.noButton.buttonMode = true;
		this.noButton.on('touchstart', () => this.closeModal())
		this.noButton.on('click', (e) => this.leftClickCloseModal(e))
		this.noButton.anchor.set(0.5);
		this.noButton.position.set(this.noButton.width * 1.4, this.background.height / 2 - 110);
		this.background.addChild(this.noButton)

		this.closeButton.interactive = true;
		this.closeButton.buttonMode = true;
		this.closeButton.on('touchstart', () => this.closeModal())
		this.closeButton.on('click', (e) => this.leftClickCloseModal(e))
		this.closeButton.anchor.set(0.5);
		this.closeButton.position.set(this.background.width / 2 - 96, -this.background.height / 2 + 101);
		this.background.addChild(this.closeButton)
	};

	leftClick = (e: any) => {
		if (e.data.button === 0) {
			this.handleClick()
		};
	}

	leftClickCloseModal = (e: any) => {
		if (e.data.button === 0) {
			this.closeModal()
		};
	}

	closeModal = () => {
		this.showModal(false)
	}

	changeText = (container: Container, text: string) => {
		container.children.forEach((item: any) => {
			if(item) {
				item.text = text;
			}
		})
	}

	resize = () => {
		let scaleByWidth = ((100 / 1920) * window.innerWidth) / 100;
		let scaleByHeight = ((100 / 1080) * window.innerHeight) / 100;

		this.windowBackground.width = window.innerWidth;
		this.windowBackground.height = window.innerHeight;
		this.modal.position.set(
			(window.innerWidth - this.modal.width) / 2,
			(window.innerHeight - this.modal.height) / 2
		);
		this.background.position.set(0, -this.background.height / 2);

		if (scaleByWidth < scaleByHeight) {
			this.background.scale.set(scaleByWidth * 1.1, scaleByWidth * 1.1);
			this.background.position.set(0, -this.background.height / 5);
			if (window.innerWidth < 600) {
				this.background.scale.set(scaleByWidth * 1.8, scaleByWidth * 1.8);
				this.background.position.set(0, -this.background.height / 2);
				if (window.innerHeight > 300) {
					this.background.scale.set(scaleByWidth * 1.4, scaleByWidth * 1.4);
					this.background.position.set(0, -30);
				}
				if(window.innerWidth < 420) {
					this.background.scale.set(scaleByWidth * 1.8, scaleByWidth * 1.8);
					this.background.position.set(0, -this.background.height / 2);
				}
			} else if (window.innerWidth <  window.innerHeight) {
				this.background.scale.set(scaleByWidth * 1.8, scaleByWidth * 1.8);
			}
		} else {
			this.background.scale.set(scaleByWidth, scaleByWidth);
			if (window.innerWidth <= 1080) {
				this.background.scale.set(scaleByHeight * 1.2, scaleByHeight * 1.2);
				this.background.position.set(0, -scaleByHeight * 100);
			} else {
				this.background.scale.set(scaleByHeight, scaleByHeight);
				this.background.position.set(0, -window.innerHeight * 0.15);
			}
		}
		this.subTitle.x = -this.subTitle.width / 2;
	}
}

export default BuyBonusConfirmModal
