import * as PIXI from 'pixi.js'
import { Game } from "../Game";
import { TextBar } from "../components/TextBar/TextBar";
import { BetInput } from "../components/BetInput/BetInput";
import { LocalStorage } from '../../utils/localStorage';

export class InfoBar {
    game: Game
    container: PIXI.Container
    balanceBar: TextBar
    winBar: TextBar
    betBar: BetInput

    constructor(game: Game) {
        this.game = game
        this.container = new PIXI.Container()
        this.balanceBar = new TextBar("BALANCE", 1000, 300, game)
        this.winBar = new TextBar("WIN", 0.00, 250, game)
        this.betBar = new BetInput('BET', LocalStorage.getItem('chosenBet') ? LocalStorage.getItem('chosenBet') : 0, 250, game)
    }

    init = async () => {

        await this.winBar.init()
        await this.balanceBar.init()
        await this.betBar.init()
        await this.betBar.init()

        this.container.addChild(this.balanceBar.input!)
        this.container.addChild(this.winBar.input!)
        this.container.addChild(this.betBar.input!)
        this.game.assetsManager.gameContainer.addChild(this.container)

        this.container.x = 550
        this.container.y = 970
        this.container.name = 'INFO_BAR';
        this.container.zIndex = 11;


        if (this.winBar.input && this.balanceBar.input && this.betBar.input) {
            this.betBar.input.x = 0
            this.betBar.input.name = 'BET_BAR'

            this.winBar.input.x = this.betBar.input.x + this.winBar.width + 40
            this.winBar.input.name = 'WIN_BAR'

            this.balanceBar.input.x = this.winBar.input.x + this.winBar.width + 40
            this.balanceBar.input.name = 'BALANCE_BAR'
        }

        this.game.resizeCallbacks.push(this.resize)
    }

    resize = () => {
        if (window.innerWidth <= 1080 || window.innerWidth / window.innerHeight <= 0.76) {
            this.container.visible = false;
            return;
        } else {
            this.container.visible = true;
        }
    }
}
