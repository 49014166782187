import { FC } from 'react';
import styled from 'styled-components';
import { respondHeightFrom } from '../../../styles/mixins';

const RulesSecondPage: FC = () => {
	return (
		<ReelsContainer>
			<Title>Free Spins:</Title>
			<ReelsText>
				Free spins are triggered by landing 3,4, or 5 bonus symbols anywhere on
				the reels.
			</ReelsText>

			<br />

			<ReelsText>
				Free spins start with a mini-game, where the player picks chests that
				contain random boosts.
			</ReelsText>
			<ReelsText>
				The number of picks available is the same as the number of scatters that
				triggered the free spins.
			</ReelsText>

			<br />
			<br />

			<ReelsText>
				The boosts include:
				<br />
				● a win multiplier of x2, x3 or x5
				<br />
				● one of the symbol types turns into wild (can be picked multiple times)
				<br />
				● one of the low pays is removed from the reels (can be picked multiple
				times)
				<br />
				● +1, 2 or 3 free spins (can be picked multiple times)
				<br />
				● wilds are sticky (every wild landing stays on the screen until the end
				of the free spins session)
				<br />
				● multiplier increases by 1 with every winning spin
				<br />
			</ReelsText>

			<br />

			<ReelsText>
				The boosts are active for the whole free spins session.
			</ReelsText>
			<ReelsText>
				The number of free spins awarded is 8 + any extra free spins from the
				boosts.
			</ReelsText>
			<ReelsText>Free spins can not be retriggered.</ReelsText>

			<br />
		</ReelsContainer>
	);
};

export default RulesSecondPage;

const Title = styled.h2`
	color: #fff;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	padding-bottom: 20px;
	${respondHeightFrom.base`
		padding-bottom: 0;
	`}
`;

const ReelsContainer = styled.div`
	flex-direction: column;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
`;

const ReelsText = styled.div`
	padding: 2px;
	color: #fff;
	display: flex;
	font-weight: 700;
	font-size: 18px;
`;
