import l1 from '../../../game/assets/symbols/L1.png';
import l2 from '../../../game/assets/symbols/L2.png';
import l3 from '../../../game/assets/symbols/L3.png';
import l4 from '../../../game/assets/symbols/L4.png';
import h1 from '../../../game/assets/symbols/H1.png';
import h2 from '../../../game/assets/symbols/H2.png';
import h3 from '../../../game/assets/symbols/H3.png';
import h4 from '../../../game/assets/symbols/H4.png';
import wild from '../../../game/assets/symbols/WILD.png';
import scatter from '../../../game/assets/symbols/SCATTER.png';
import chest from '../../../game/assets/symbols/CHEST.png';

export const reelsSymbolList = [
	{
		text: `
			<div>
			5x = 4 $
            <br />
            4x = 1 $
            <br />
            3x = 0.5 $
			</div>

			<img src=${h1} alt='Free Spins' />
            `,
	},
	{
		text: `
			<div>
			5x = 2 $
            <br />
            4x = 0.7 $
            <br />
            3x = 0.3 $
			</div>
			<img src=${h2} alt='Free Spins' />
            `,
	},
	{
		text: `
			<div>
			5x = 1 $
            <br />
            4x = 0.5 $
            <br />
            3x = 0.2 $
			</div>

			<img src=${h3} alt='Free Spins' />
            `,
	},
	{
		text: `
			<div>
			5x = 1 $
            <br />
            4x = 0.5 $
            <br />
            3x = 0.2 $
			</div>

			<img src=${h4} alt='Free Spins' />
            `,
	},
	{
		text: `
			<div>
			5x = 0.5 $
            <br />
            4x = 0.3 $
            <br />
            3x = 0.1 $
			</div>
			<img src=${l1} alt='Free Spins' />
            `,
	},
	{
		text: `
			<div>
			5x = 0.5 $
            <br />
            4x = 0.3 $
            <br />
            3x = 0.1 $
			</div>
			<img src=${l2} alt='Free Spins' />
            `,
	},
	{
		text: `
			<div>
			5x = 0.5 $
            <br />
            4x = 0.3 $
            <br />
            3x = 0.1 $
			</div>
			<img src=${l3} alt='Free Spins' />
            `,
	},
	{
		text: `
			<div>
			5x = 0.5 $
            <br />
            4x = 0.3 $
            <br />
            3x = 0.1 $
			</div>
			<img src=${l4} alt='Free Spins' />
            `,
	},
];

export const reelsSpecialSymbolList = [
	{
		text: `
			<img src=${scatter} alt='Free Spins' />
			<div>
				3, 4 or 5 BONUS symbols trigger free spins with <br /> 3, 4 or 5
				feature picks respectively.
			</div>
            `,
	},
	{
		text: `
			<img src=${wild} alt='Free Spins' />
			<div>
				This is the WILD symbol.
				<br />
				It substitutes for all symbols except BONUS and CHEST.
			</div>
		`,
	},
	{
		text: `
			<img src=${chest} alt='Free Spins' />
			<div>CHEST symbol triggers a respin.</div>
		`,
	},
];
