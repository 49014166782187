
import { Game } from '../../Game'
import {EventType, getEvent} from "../../../GameEventBus";
import { MODAL_ASSETS } from '../../assetsLoader/modalLoader'
import { Container, Graphics, Sprite, Texture } from 'pixi.js';
import { Sounds } from '../../classes/SoundController';
import { SoundNames } from '../../constants/sounds';
import { LocalStorage } from '../../../utils/localStorage';
import { buyBonusTextStyles } from './buyBonusTextStyles';
import { buyBonusConfirmTextStyles } from './buyBonusConfirmTextStyles';

class BuyBonusModal {
	game: Game
	modalWrapper: Container;
	windowBackground: Graphics;
	modal: Sprite;
	background: Sprite;
	title: Sprite;
	image: Sprite;
	option: Container;
	subTitle: Container;
	closeButton: Sprite;
	yesButton: Sprite;
	isModalOpen: boolean
	isBuyBonus: boolean;
	inputBg: Sprite;
	plusBtn: Sprite;
	minusBtn: Sprite;
	betValue: Container;
	totalBetList: number[];
    selectedBetIndex: number;
    currentBetValue: number;
    isPlusMaxVal?: boolean
    isMinusMinVal?: boolean
	currency: string;

	constructor(game: Game) {
		this.game = game
		this.isModalOpen = false
		this.modal = new Sprite();
		this.modalWrapper = new Container();
		this.background = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_BG));
		this.closeButton = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_CLOSE));
		this.yesButton = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_BUY));
		this.windowBackground = new Graphics();
		this.title = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_TITLE));
		this.image = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_SCATTERS));
		this.option = buyBonusTextStyles.addText('3-5 FREE SPINS', 42);
		this.subTitle = buyBonusTextStyles.addText('', 34);
		this.isBuyBonus = false;
		this.inputBg = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_INPUT));
		this.plusBtn = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_PLUS));
		this.minusBtn = new Sprite(Texture.from(MODAL_ASSETS.BUY_BONUS_MODAL_MINUS));
		this.betValue = buyBonusConfirmTextStyles.addText('1 USD', 30);
		this.totalBetList = [0]
        this.selectedBetIndex = LocalStorage.getItem('chosenBet') ? LocalStorage.getItem('chosenBet') : 0;
        this.currentBetValue = this.totalBetList[this.selectedBetIndex];
		this.currency = "USD";
	}

	init = async () => {
		await this.setupButtons();
		this.modalWrapper.position.set(0, 0);
		this.modalWrapper.width = window.innerWidth
		this.modalWrapper.height = window.innerHeight
		this.modalWrapper.visible = false
		this.modalWrapper.zIndex = 10000

		this.windowBackground.beginFill(0x151515)
		this.windowBackground.drawRect(0, 0, window.innerWidth, window.innerHeight);
		this.windowBackground.endFill()
		this.windowBackground.alpha = 0.8

		this.background.anchor.set(0.5);
		this.windowBackground.zIndex = 1
		this.modal.zIndex = 15;
		this.background.width = 987;
		this.background.height = 686;

		this.modal.anchor.set(0.5);
		this.image.anchor.set(0.5);
		this.title.anchor.set(0.5);

		this.title.position.set(0, -this.title.height * 2.35);
		this.image.position.set(0, this.image.height / 2 - 130);
		this.option.position.set(-this.option.width / 2, -168);
		this.subTitle.position.set(-this.subTitle.width / 2, 115);
		this.betValue.position.set(-this.betValue.width / 2, 233.5);

		this.game.app.stage.addChild(this.modalWrapper)
		this.modalWrapper.addChild(this.windowBackground);
		this.modalWrapper.addChild(this.modal);
		this.modal.addChild(this.background);
		this.background.addChild(this.title);
		this.background.addChild(this.image);
		this.background.addChild(this.option);
		this.background.addChild(this.subTitle);
		this.background.addChild(this.betValue);
		this.game.resizeCallbacks.push(this.resize)
	};

	handleClick = async () => {
		if (this.game.slotMachine) {
			if(this.game.slotMachine!.bet * 100 > this.game.slotMachine.balance) {
				let errorArray = ["Not enough funds", "Please refill your balance", '']
				getEvent(EventType.ERROR).send(errorArray);
				return
			}
			this.game.assetsManager.buyBonusConfirmModal?.showModal(true)
			this.showModal(false)
		} else {
			this.showModal(false)
		}
	}
	showModal = async (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true
			this.game.assetsManager.gameContainer.interactiveChildren = false
			if(this.game.initData.account) {
				const currency = this.game.initData.account.currency ? this.game.initData.account.currency : "USD"
				this.currency = currency;
			}
			this.selectedBetIndex = this.game.assetsManager.infoBar?.betBar.selectedBetIndex! ? this.game.assetsManager.infoBar?.betBar.selectedBetIndex! : this.selectedBetIndex;
			this.changeText(this.subTitle, `${this.game.slotMachine!.bet * 100} ${this.currency}`);
			this.changeText(this.betValue, `${this.totalBetList[this.game.assetsManager.infoBar?.betBar.selectedBetIndex!]} ${this.currency}`);
		} else {
			this.modalWrapper.visible = false
			this.game.assetsManager.gameContainer.interactiveChildren = true
		}
		this.resize()
	};

	setupButtons = async () => {
		this.inputBg.anchor.set(0.5);
		this.plusBtn.anchor.set(0.5);
		this.minusBtn.anchor.set(0.5);

		this.inputBg.position.set(0, 250);
		this.plusBtn.position.set(121, 249);
		this.minusBtn.position.set(-121, 249);

		this.plusBtn.interactive = true;
		this.plusBtn.buttonMode = true;
		this.minusBtn.interactive = true;
		this.minusBtn.buttonMode = true;

		this.plusBtn.on('touchstart', () => this.handlePlusClick())
		this.plusBtn.on('click', (e) => this.leftPlusClick(e))
		this.minusBtn.on('touchstart', () => this.handleMinusClick())
		this.minusBtn.on('click', (e) => this.leftMinusClick(e))

		this.background.addChild(this.inputBg)
		this.background.addChild(this.plusBtn)
		this.background.addChild(this.minusBtn)

		this.yesButton.interactive = true;
		this.yesButton.buttonMode = true;
		this.yesButton.on('touchstart', () => this.handleClick())
		this.yesButton.on('click', (e) => this.leftClick(e))
		this.yesButton.anchor.set(0.5);
		this.yesButton.position.set(0, 180);
		this.background.addChild(this.yesButton)

		this.closeButton.interactive = true;
		this.closeButton.buttonMode = true;
		this.closeButton.on('touchstart', () => this.closeModal())
		this.closeButton.on('click', (e) => this.leftClickCloseModal(e))
		this.closeButton.anchor.set(0.5);
		this.closeButton.position.set(this.background.width / 2 - 96, -this.background.height / 2 + 101);
		this.background.addChild(this.closeButton)
	};

	leftClick = (e: any) => {
		if (e.data.button === 0) {
			this.handleClick()
		};
	}

	leftClickCloseModal = (e: any) => {
		if (e.data.button === 0) {
			this.closeModal()
		};
	}

	closeModal = () => {
		this.showModal(false)
	}

	leftPlusClick = (e: any) => {
        if (e.data.button === 0) {
            this.handlePlusClick()
        }
    }

	leftMinusClick = (e: any) => {
        if (e.data.button === 0) {
            this.handleMinusClick()
        }
    }

	async handleMinusClick() {
        Sounds.play(SoundNames.BTN_UI);
        if (!this.game.slotMachine) return;
        if (this.selectedBetIndex > 0) {
			this.selectedBetIndex--;
        } else if (this.selectedBetIndex === 0) {
			this.selectedBetIndex = 0;
        }
        this.isPlusMaxVal = false;
        this.currentBetValue = this.totalBetList[this.selectedBetIndex];
		this.changeText(this.betValue, `${this.currentBetValue} ${this.currency}`);
		this.changeText(this.subTitle, `${this.totalBetList[this.selectedBetIndex] * 100} ${this.currency}`);
        this.game.slotMachine.bet = this.currentBetValue;
        getEvent(EventType.CHOSEN_BET).send(this.selectedBetIndex);
		
		this.alightCenter();
        if (this.currentBetValue <= 0) return
        if (this.selectedBetIndex === 0) {
            this.minusBtn.interactive = true;
        }
    }

    handlePlusClick() {
        Sounds.play(SoundNames.BTN_UI);
        if (!this.game.slotMachine) return;
        if (this.selectedBetIndex < this.totalBetList.length - 1 && this.selectedBetIndex !== this.totalBetList.length - 1) {
            this.selectedBetIndex++;
        } else if (this.selectedBetIndex === this.totalBetList.length - 1 ) {
            this.selectedBetIndex = this.totalBetList.length - 1;
        }
        this.isMinusMinVal = false;
        this.currentBetValue = this.totalBetList[this.selectedBetIndex];
		this.changeText(this.betValue, `${this.currentBetValue} ${this.currency}`);
		this.changeText(this.subTitle, `${this.totalBetList[this.selectedBetIndex] * 100} ${this.currency}`);
        this.game.slotMachine.bet = this.currentBetValue;
        getEvent(EventType.CHOSEN_BET).send(this.selectedBetIndex)
		
		this.alightCenter();
        if (this.selectedBetIndex === this.totalBetList.length - 1) {
			this.plusBtn.interactive = true;
        }
    }

	setBetList = async (value: number[]) => {
        this.totalBetList = value;
        this.currentBetValue = this.totalBetList[this.selectedBetIndex];
		this.changeText(this.betValue, `${this.currentBetValue} ${this.currency}`);
    }

	alightCenter = () => {
		this.option.x = -this.option.width / 2;
		this.subTitle.x = -this.subTitle.width / 2;
		this.betValue.x = -this.betValue.width / 2;
	}

	changeText = (container: Container, text: string) => {
		container.children.forEach((item: any) => {
			if(item) {
				item.text = text;
			}
		})
	}

	resize = () => {
		let scaleByWidth = ((100 / 1920) * window.innerWidth) / 100;
		let scaleByHeight = ((100 / 1080) * window.innerHeight) / 100;

		this.windowBackground.width = window.innerWidth;
		this.windowBackground.height = window.innerHeight;
		this.modal.position.set(
			(window.innerWidth - this.modal.width) / 2,
			(window.innerHeight - this.modal.height) / 2
		);
		this.background.position.set(0, -this.background.height / 2);

		if (scaleByWidth < scaleByHeight) {
			this.background.scale.set(scaleByWidth * 1.1, scaleByWidth * 1.1);
			this.background.position.set(0, -this.background.height / 5);
			if (window.innerWidth < 600) {
				this.background.scale.set(scaleByWidth * 1.8, scaleByWidth * 1.8);
				this.background.position.set(0, -this.background.height / 2);
				if (window.innerHeight > 300) {
					this.background.scale.set(scaleByWidth * 1.4, scaleByWidth * 1.4);
					this.background.position.set(0, -30);
				}
				if(window.innerWidth < 420) {
					this.background.scale.set(scaleByWidth * 1.8, scaleByWidth * 1.8);
					this.background.position.set(0, -this.background.height / 2);
				}
			} else if (window.innerWidth <  window.innerHeight) {
				this.background.scale.set(scaleByWidth * 1.8, scaleByWidth * 1.8);
			}
		} else {
			this.background.scale.set(scaleByWidth, scaleByWidth);
			if (window.innerWidth <= 1080) {
				this.background.scale.set(scaleByHeight * 1.2, scaleByHeight * 1.2);
				this.background.position.set(0, -scaleByHeight * 100);
			} else {
				this.background.scale.set(scaleByHeight, scaleByHeight);
				this.background.position.set(0, -window.innerHeight * 0.15);
			}
		}
		this.alightCenter();
	}
}

export default BuyBonusModal
