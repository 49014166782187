import {Container, Text, TextStyle} from "pixi.js";

class BuyBonusTextStyles {
    fontFamily: string;
    size: number;

    constructor() {
        this.fontFamily = "Norse-Bold";
        this.size = 40;
    }

    addText(text: string, fontSize: number) {
        this.size = fontSize;
        const textContainer = new Container();

        const shadow = new Text(text, this.textShadow());
      
        shadow.x = 7;
        shadow.y = 6;
        shadow.alpha = 0.55;
        textContainer.addChild(shadow);

        const underMain = new Text(text, this.textUnderMainFont());
        underMain.x = 7;
        underMain.y = 4;
        textContainer.addChild(underMain);

        const secondUnderMain = new Text(text, this.textSecondUnderMainFont());
        secondUnderMain.x = 4.5;
        secondUnderMain.y = 1;
        textContainer.addChild(secondUnderMain);

        const main = new Text(text, this.textMainFont());
        main.x = 5;
        main.y = 2;
        textContainer.addChild(main);
        return textContainer;
    }

    textUnderMainFont() {
        const color = "#28484f";
        const style = new TextStyle({
            fill: color,
            fontFamily: this.fontFamily,
            fontSize: this.size,
            miterLimit: 2,
            letterSpacing: 0,
            padding: 5,
        });
        return style;
    }

    textSecondUnderMainFont() {
        const color = "#ffe659";
        const style = new TextStyle({
            fill: color,
            fontFamily: this.fontFamily,
            fontSize: this.size,
            miterLimit: 2,
            letterSpacing: 0,
            padding: 5,
            stroke: "#ffe659",
            strokeThickness: 5,
        });
        return style;
    }

    textMainFont() {
        const gradient = ["#ffff59", "#ffffe6", "#ffebc6", "#ffff45", "#c57f00", "#d99208", "#a9691a", "#762e15","#d55c05", "#8b3201"];
        const style = new TextStyle({
            fill: gradient,
            fontFamily: this.fontFamily,
            fontSize: this.size,
            miterLimit: 2,
            letterSpacing: 0,
            padding: 5,
            stroke: "#3d0d02",
            strokeThickness: 3,
        });
        return style;
    }

    textShadow() {
        const style = new TextStyle({
            fill: "#202026",
            fontFamily: this.fontFamily,
            fontSize: this.size,
            miterLimit: 2,
            strokeThickness: 5,
            letterSpacing: 0,
            padding: 5,
        });
        return style;
    }
}

export const buyBonusTextStyles = new BuyBonusTextStyles();
