import {Loader} from "pixi.js";
import PlusBtn from '../assets/info-bar/btn-plus-default.svg'
import PlusBtnHover from '../assets/info-bar/btn-plus-hover.svg'
import MinusBtn from '../assets/info-bar/btn-minus-default.svg'
import MinusBtnHover from '../assets/info-bar/btn-minus-hover.svg'
import PlayBtnIcon from '../assets/buttons/play-btn-icon.png'
import PlayBtnIconHover from '../assets/buttons/play-btn-icon-hover.png'
import PlayBtnBg from '../assets/buttons/play-btn-background.png'
import PlayBtnBorder from '../assets/buttons/play-btn-border.png'
import playBtnBorderFS from '../assets/buttons/play-btn-fs-border.png'
import StopBtnIcon from '../assets/buttons/stop-btn-icon.png'
import StopBtnIconHover from '../assets/buttons/stop-btn-icon-hover.png'

import AutoPlayBtnBg from '../assets/buttons/auto-play-btn-background.png'
import AutoPlayBtnBorder from "../assets/buttons/auto-play-btn-border.png";
import AutoPlayBtnIcon from '../assets/buttons/auto-play-btn-icon.png'
import AutoPlayBtnIconHover from '../assets/buttons/auto-play-btn-icon-hover.png'
import AutoPlayBtnActive from '../assets/buttons/auto-play-btn-icon-active.png'

import BtnInfoDefault from '../assets/info-bar/btn-info-default.png'
import BtnInfoHidden from '../assets/info-bar/btn-info-hidden.png'
import BtnInfoHover from '../assets/info-bar/btn-info-hover.png'
import BuyBonusBtnDefault from '../assets/buttons/buy-bonus-btn-default.png'
import BuyBonusBtnHover from '../assets/buttons/buy-bonus-btn-hover.png'

import MenuBtnDefault from '../assets/buttons/menu-btn-default.png'
import MenuBtnHover from '../assets/buttons/menu-btn-hover.png'
import MenuBtnHiden from '../assets/buttons/menu-btn-hiden.png'

import BurgerMenuDefaultBtn from  '../assets/buttons/btn-burger-default.png'
import BurgerMenuActiveBtn from  '../assets/buttons/btn-burger-active.png'
import BurgerMenuInactiveBtn from  '../assets/buttons/btn-burger-inactive.png'

import BetDefaultBtn from  '../assets/buttons/btn-bet-default.png'
import BetActiveBtn from  '../assets/buttons/btn-bet-active.png'
import BetInactiveBtn from  '../assets/buttons/btn-bet-inactive.png'

import confirmButtonDefault from "../assets/buttons/btn-confirm-default.png";
import confirmButtonHover from "../assets/buttons/btn-confirm-hover.png";
import confirmButtonDisabled from "../assets/buttons/btn-confirm-disabled.png";
import okBtn from "../assets/free_spins_summary/btn.png"

import winStartBtnDefault from "../assets/modal/win-start-btn-default.png";

import closeButtonDefault from "../assets/buttons/btn-close-default.png"
import closeButtonHover from "../assets/buttons/btn-close-hover.png"
import closeButtonDisabled from "../assets/buttons/btn-close-disabled.png"

import buyBonusBtn from "../assets/buttons/buy-bonus-btn.png"

export enum BUTTONS_ASSETS {
    PLUS_BTN = 'PLUS_BTN',
    PLUS_BTN_HOVER = "PLUS_BTN_HOVER",
    MINUS_BTN = "MINUS_BTN",
    MINUS_BTN_HOVER = "MINUS_BTN_HOVER",
    PLAY_BTN_BG = "PLAY_BTN_BG",
    PLAY_BTN_BORDER = "PLAY_BTN_BORDER",
    PLAY_BTN_ICON = "PLAY_BTN_ICON",
    PLAY_BTN_ICON_HOVER = "PLAY_BTN_ICON_HOVER",
    STOP_BTN_ICON = "STOP_BTN_ICON",
    STOP_BTN_ICON_HOVER = "STOP_BTN_ICON_HOVER",
    AUTO_PLAY_BTN_BG = "AUTO_PLAY_BTN_BG",
    AUTO_PLAY_BTN_BORDER = "AUTO_PLAY_BTN_BORDER",
    AUTO_PLAY_BTN_ICON = "AUTO_PLAY_BTN_ICON",
    AUTO_PLAY_BTN_ICON_HOVER = "AUTO_PLAY_BTN_ICON_HOVER",
    AUTO_PLAY_BTN_ICON_ACTIVE = "AUTO_PLAY_BTN_ICON_ACTIVE",
    BTN_INFO_DEFAULT = "BTN_INFO_DEFAULT",
    BTN_INFO_HIDDEN = "BTN_INFO_HIDDEN",
    BTN_INFO_HOVER = "BTN_INFO_HOVER",
    BUY_BONUS_BTN_DEFAULT = "BUY_BONUS_BTN_DEFAULT",
    BUY_BONUS_BTN_HOVER = "BUY_BONUS_BTN_HOVER",
    MENU_BTN_DEFAULT = "MENU_BTN_DEFAULT",
    MENU_BTN_HOVER = "MENU_BTN_HOVER",
    MENU_BTN_HIDEN = "MENU_BTN_HIDEN",
    BURGER_MENU_DEFAULT_BTN = "BURGER_MENU_DEFAULT_BTN",
    BURGER_MENU_ACTIVE_BTN = "BURGER_MENU_ACTIVE_BTN",
    BURGER_MENU_INACTIVE_BTN = "BURGER_MENU_INACTIVE_BTN",
    BET_DEFAULT_BTN = "BET_DEFAULT_BTN",
    BET_ACTIVE_BTN = "BET_ACTIVE_BTN",
    BET_INACTIVE_BTN = "BET_INACTIVE_BTN",
    CONFIRM_BTN_DEFAULT = "CONFIRM_BTN_DEFAULT",
    CONFIRM_BTN_HOVER = "CONFIRM_BTN_HOVER",
    CONFIRM_BTN_DISABLED = "CONFIRM_BTN_DISABLED",
    PLAY_BTN_BORDER_FS = "PLAY_BTN_BORDER_FS",
    WIN_START_BTN_DEFAULT = "WIN_START_BTN_DEFAULT",
    OK_BTN = "OK_BTN",
    CLOSE_BTN_DEFAULT = "CLOSE_BTN_DEFAULT",
    CLOSE_BTN_HOVER = "CLOSE_BTN_HOVER",
    CLOSE_BTN_DISABLED = "CLOSE_BTN_DISABLED",
    BUY_BONUS_BTN = "BUY_BONUS_BTN",
}


export const loadButtonsAssets = (loader: Loader) => {
    loader.add(BUTTONS_ASSETS.PLUS_BTN, PlusBtn)
    loader.add(BUTTONS_ASSETS.PLUS_BTN_HOVER, PlusBtnHover)
    loader.add(BUTTONS_ASSETS.MINUS_BTN, MinusBtn)
    loader.add(BUTTONS_ASSETS.MINUS_BTN_HOVER, MinusBtnHover)
    loader.add(BUTTONS_ASSETS.PLAY_BTN_BG, PlayBtnBg)
    loader.add(BUTTONS_ASSETS.PLAY_BTN_BORDER, PlayBtnBorder)
    loader.add(BUTTONS_ASSETS.PLAY_BTN_ICON, PlayBtnIcon)
    loader.add(BUTTONS_ASSETS.PLAY_BTN_ICON_HOVER, PlayBtnIconHover)
    loader.add(BUTTONS_ASSETS.STOP_BTN_ICON, StopBtnIcon)
    loader.add(BUTTONS_ASSETS.STOP_BTN_ICON_HOVER, StopBtnIconHover)
    loader.add(BUTTONS_ASSETS.AUTO_PLAY_BTN_BG, AutoPlayBtnBg)
    loader.add(BUTTONS_ASSETS.AUTO_PLAY_BTN_BORDER, AutoPlayBtnBorder)
    loader.add(BUTTONS_ASSETS.AUTO_PLAY_BTN_ICON, AutoPlayBtnIcon)
    loader.add(BUTTONS_ASSETS.AUTO_PLAY_BTN_ICON_HOVER, AutoPlayBtnIconHover)
    loader.add(BUTTONS_ASSETS.AUTO_PLAY_BTN_ICON_ACTIVE, AutoPlayBtnActive)
    loader.add(BUTTONS_ASSETS.BTN_INFO_DEFAULT, BtnInfoDefault)
    loader.add(BUTTONS_ASSETS.BTN_INFO_HIDDEN, BtnInfoHidden)
    loader.add(BUTTONS_ASSETS.BTN_INFO_HOVER, BtnInfoHover)
    loader.add(BUTTONS_ASSETS.BUY_BONUS_BTN_DEFAULT, BuyBonusBtnDefault)
    loader.add(BUTTONS_ASSETS.BUY_BONUS_BTN_HOVER, BuyBonusBtnHover)
    loader.add(BUTTONS_ASSETS.MENU_BTN_DEFAULT, MenuBtnDefault)
    loader.add(BUTTONS_ASSETS.MENU_BTN_HOVER, MenuBtnHover)
    loader.add(BUTTONS_ASSETS.MENU_BTN_HIDEN, MenuBtnHiden)
    loader.add(BUTTONS_ASSETS.BURGER_MENU_DEFAULT_BTN, BurgerMenuDefaultBtn)
    loader.add(BUTTONS_ASSETS.BURGER_MENU_ACTIVE_BTN, BurgerMenuActiveBtn)
    loader.add(BUTTONS_ASSETS.BURGER_MENU_INACTIVE_BTN, BurgerMenuInactiveBtn)
    loader.add(BUTTONS_ASSETS.BET_DEFAULT_BTN, BetDefaultBtn)
    loader.add(BUTTONS_ASSETS.BET_ACTIVE_BTN, BetActiveBtn)
    loader.add(BUTTONS_ASSETS.BET_INACTIVE_BTN, BetInactiveBtn)
    loader.add(BUTTONS_ASSETS.CONFIRM_BTN_DEFAULT, confirmButtonDefault)
    loader.add(BUTTONS_ASSETS.CONFIRM_BTN_HOVER, confirmButtonHover)
    loader.add(BUTTONS_ASSETS.CONFIRM_BTN_DISABLED, confirmButtonDisabled)
    loader.add(BUTTONS_ASSETS.PLAY_BTN_BORDER_FS, playBtnBorderFS)
    loader.add(BUTTONS_ASSETS.WIN_START_BTN_DEFAULT, winStartBtnDefault)
    loader.add(BUTTONS_ASSETS.OK_BTN, okBtn)
    loader.add(BUTTONS_ASSETS.CLOSE_BTN_DEFAULT, closeButtonDefault)
    loader.add(BUTTONS_ASSETS.CLOSE_BTN_HOVER, closeButtonHover)
    loader.add(BUTTONS_ASSETS.CLOSE_BTN_DISABLED, closeButtonDisabled)
    loader.add(BUTTONS_ASSETS.BUY_BONUS_BTN, buyBonusBtn)
    
}
