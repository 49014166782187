import {FC, useCallback, useEffect, useState} from 'react';
import { Howler } from 'howler';
import iconExit from '../../../assets/img/header/icon-exit.svg';
import iconRules from '../../../assets/img/header/icon-rules.svg';
import iconSettings from '../../../assets/img/header/icon-settings.svg';
import iconSoundOn from '../../../assets/img/header/icon-sound-on.svg';
import iconSoundOff from '../../../assets/img/header/icon-sound-off.svg';
import iconExitHover from '../../../assets/img/header/icon-exit-hover.svg';
import iconRulesHover from '../../../assets/img/header/icon-rules-hover.svg';
import iconSettingsHover from '../../../assets/img/header/icon-settings-hover.svg';
import iconSoundOnHover from '../../../assets/img/header/icon-sound-on-hover.svg';
import iconSoundOffHover from '../../../assets/img/header/icon-sound-off-hover.svg';
import iconSettingsClose from '../../../assets/img/header/header-btn-close.svg'
import { Game } from "../../../game/Game";
import { Group, Popover } from "@mantine/core";
import styled from "styled-components";
import { respondFrom } from "../../../styles/mixins";
import MenuModal, { MenuList } from "../../Modals";
import Settings from "../../Settings";
import TokenStorage from '../../../helpers/TokenStorage';
import { EventType, getEvent } from '../../../GameEventBus';
import { Sounds } from '../../../game/classes/SoundController';
import { SoundNames } from '../../../game/constants/sounds';
import {LocalStorage} from "../../../utils/localStorage";


export interface ComponentProps {
    game: Game;
}

const NavBar: FC<ComponentProps> = ({ game }) => {
    const audioState = LocalStorage.getItem('isAudioEnable') === null ? true : LocalStorage.getItem('isAudioEnable')
    const [activeModal, setActiveModal] = useState(MenuList.Rules);
    const [isSoundOn, setIsSoundOn] = useState(audioState);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [settingsStatus, setSettingsStatus] = useState(false)

    const showMenuModal = useCallback((modalType: MenuList = MenuList.Rules) => {
        Sounds.play(SoundNames.BTN_UI);
        game.assetsManager?.burgerMenuBtn?.changeMenuState(true)
        setIsModalOpen(true)
        setActiveModal(modalType);
    }, [setIsModalOpen, setActiveModal])

    const hideMenuModal = useCallback((modalType: MenuList = MenuList.Rules) => {
        Sounds.play(SoundNames.BTN_UI);
        game.assetsManager?.burgerMenuBtn?.changeMenuState(false)
        setIsModalOpen(false)
        setActiveModal(modalType);
    }, [setIsModalOpen, setActiveModal])

    const exitHandler = useCallback(() => {
        Sounds.play(SoundNames.BTN_UI);
        TokenStorage.removeToken();
        window.location.reload();
    }, []);

    const openSettingsPopup = (state: boolean) => {
		Sounds.play(SoundNames.BTN_UI);
        setSettingsStatus(state);
	};

    const switchSound = useCallback((state: boolean) => {
        if (state) {
            Howler.volume(0.5);
        } else {
            Howler.volume(0);
        }
        setIsSoundOn(state);
        getEvent(EventType.AUDIO_TOGGLE).send(state);
        LocalStorage.setItem('isAudioEnable', state)
    }, [setIsSoundOn, isSoundOn]);

    useEffect(() => {
        switchSound(LocalStorage.getItem('isAudioEnable') === null ? true : LocalStorage.getItem('isAudioEnable'))
    })

    getEvent<boolean>(EventType.AUDIO_TOGGLE).subscribe(value => {
		setIsSoundOn(value);
	})
    
    return (
        <>
            <GroupWrapper>
                <DesktopView>
                    <Popover opened={settingsStatus} onChange={() => openSettingsPopup(false)} width={304} trapFocus position='bottom' withArrow>
                        <Popover.Target>
                            <NavButton onClick={() => openSettingsPopup(!settingsStatus)}>
                                <img src={settingsStatus ? iconSettingsClose : iconSettings} alt='Settings' draggable={false} />
                                <img src={settingsStatus ? iconSettingsClose : iconSettingsHover} alt='Settings' draggable={false} />
                            </NavButton>
                        </Popover.Target>
                        <Settings
                            isSoundOn={isSoundOn}
                            setIsSoundOn={setIsSoundOn} />
                    </Popover>

                    <NavButton onClick={() => switchSound(!isSoundOn)}>
                        <img
                            src={isSoundOn ? iconSoundOn : iconSoundOff}
                            alt='Sound'
                            draggable={false}
                        />
                        <img
                            src={isSoundOn ? iconSoundOnHover : iconSoundOffHover}
                            alt='Sound'
                            draggable={false}
                        />
                    </NavButton>

                    <NavButton onClick={() => showMenuModal(MenuList.Rules)}>
                        <img src={iconRules} alt='Rules' draggable={false} />
                        <img src={iconRulesHover} alt='Rules' draggable={false} />
                    </NavButton>

                    <NavButton onClick={() => exitHandler()}>
                        <img src={iconExit} alt='Exit' draggable={false} />
                        <img src={iconExitHover} alt='Exit' draggable={false} />
                    </NavButton>
                </DesktopView>
            </GroupWrapper>

            <MenuModal
                game={game}
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                isModalOpen={isModalOpen}
                hideMenuModal={hideMenuModal}
            />
        </>
    );
};

export default NavBar;

const GroupWrapper = styled(Group)``;

const DesktopView = styled(GroupWrapper)`
	display: flex;
	align-items: center;
	padding-right: 48px;
	${respondFrom.lg`
		display: none;
	`}
`;

const NavButton = styled.button`
	color: #e2f4f2;
	height: 32px;
	width: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: none;
	cursor: pointer;
	img {
		display: inline-block;
	}
	img:last-child {
		display: none;
	}
	&:hover {
		background-color: #151515;
		transition: all ease 0.5s;
		img:first-child {
			display: none;
		}
		img:last-child {
			display: inline-block;
		}
	}
`;
