import {slotMachine} from "./slotMachine";
import {Game} from "../Game";
import {Container, Graphics} from "pixi.js";
import {FeatureDetailed, PlayResponse, WinEvent} from "../../api/types";
import {SYMBOL_SIZE} from "../constants/Symbols";
import {EventType, getEvent} from "../../GameEventBus";
import { Timer } from "../../utils/Timer";
import { Sounds } from './SoundController';
import { SoundNames } from '../constants/sounds';

export class SlotEvents  {
    slotMachine: slotMachine
    game: Game
    isStickyWild: boolean;

    constructor(slotMachine: slotMachine) {
        this.slotMachine = slotMachine
        this.game = slotMachine.game
        this.isStickyWild = false;
        this.turboListener()
    }

    placeWinBorder = (container: Container, color = 0x00e63d) => {
        const border = new Graphics();
        border.lineStyle(14, color, 1);
        border.beginFill(131, 0)
        border.drawRect(8, 8, SYMBOL_SIZE - 2, SYMBOL_SIZE - 2);
        border.endFill();
        container.addChild(border);
    }


    startMiniGame = ( features: FeatureDetailed[], extraFreeSpins: number) => {
        if(!this.game.assetsManager.miniGame) return
        this.game.assetsManager.miniGame.receivedFeatures = features
        Sounds.play(SoundNames.FS_TRIGGER);
        this.game.assetsManager.winStartPopUp?.showModal(true, `${this.game.slotMachine?.freeSpinCount! - extraFreeSpins}`)
        this.game.assetsManager.miniGame?.toggleMiniGame(true)
    }


    startRespinMinigame = (features: FeatureDetailed[]) => {
        if(!this.game.assetsManager.miniGame) return
        this.game.assetsManager.miniGame.receivedFeatures = features;
        this.game.assetsManager.miniGame.setupRespinMinigame();
    }

    buildWinLine = (result: PlayResponse, events: WinEvent[], isBigWin: string | undefined) => {
        let isWinLines = !!events.length
        return new Promise(async (resolve, reject) => {
            const winLines = events.filter((event) => event.type !== 'ChestDropped')
            if(!winLines.length) return resolve(true)
            this.game.assetsManager.playBtn!.disableOnWin();

            const higthSound = winLines.filter((event) => ['H1', 'H2', 'H3', 'H4'].includes(event.symbol))
            const metalSound = winLines.filter((event) => ['L1', 'L2', 'L4'].includes(event.symbol))
            const bowSound = winLines.filter((event) => ['L3'].includes(event.symbol))

            if (higthSound.length !== 0) {
                Sounds.play(SoundNames.WIN);
            } else if (metalSound.length !== 0) {
                Sounds.play(SoundNames.WIN_METAL);
            } else if (bowSound.length !== 0) {
                const soundTimer = new Timer(() => {
                    Sounds.play(SoundNames.WIN_BOW);
                }, 450);
                soundTimer.initialize();
            }
            const spin = ![''].includes(this.slotMachine.nextCommand);
            await this.game.assetsManager.slowWin?.setWinLines(winLines);
            if (spin || isBigWin) {
                winLines.forEach((event) => {
                    const eventData = ("StickyWild" in event) ? event.StickyWild![0] : event;
                    if("StickyWild" in event) this.isStickyWild = true;
                    eventData.coords.forEach((cord) => {
                        const symbol = this.slotMachine.reels[cord.x].symbols[3 - cord.y]
                        if(!["CHEST"].includes(symbol.name)) {
                            symbol.win(this.game, cord)
                            if(symbol.name === "WILD") {
                                this.slotMachine.reels[cord.x].container.zIndex = 400
                            } else {
                                this.slotMachine.reels[cord.x].container.zIndex = cord.x
                            }
                        } else {
                            this.placeWinBorder(symbol.container)
                        }
                    })
                })
            }

            if (!this.game.slotMachine?.isFsRunning) this.changeWin(result.cashWin)
            
            const timer = new Timer(() => {
                return resolve(true)
            }, spin ? isWinLines ? 2000 : 1000 : 0)
            timer.initialize()

        })

    }

    buildSpecialEvents = (events: WinEvent[]) => {
        let isWinLines = !!events.length
        return new Promise((resolve, reject) => {
            const filteredEvents = events?.filter((event) => event.type === 'ScatterWin' || event.type === 'ChestDropped')
            if(!filteredEvents.length) return resolve(true)
            this.game.assetsManager.playBtn!.disableOnWin();
            const chestDropped = events?.filter((event) => event.type === 'ChestDropped')
            if(chestDropped.length) {
                chestDropped.forEach((event) => {
                    event.coords.forEach((cord) => {
                        const symbol = this.slotMachine.reels[cord.x].symbols[3 - cord.y]
                        if(["CHEST"].includes(symbol.name)) {
                            const timer = new Timer(() => {
                            symbol.spine?.state.setAnimation(0, "opening_anim", false);
                            symbol.spine?.state.addListener({complete: entry => {
                                Sounds.play(SoundNames.RESPIN_TRIGGER);
                                this.game.assetsManager.slowWin!.forbidden = true;
                                this.game.assetsManager.gameField?.feature?.setupPosition(chestDropped[0].coords[0].x, chestDropped[0].coords[0].y);
                                this.game.assetsManager.gameField?.feature?.animate().then(() => {
                                    return resolve(true)
                                });
                            }})
                            }, 1000)
                            timer.initialize()
                        }
                    })
                })
            } else {
                const timer = new Timer(() => {
                    return resolve(true)
                }, isWinLines ? 500 : 1)
                timer.initialize()
            }
        })

    }

	openWinModal = async (winDescription: string, cashWin: number) => {
		this.game.assetsManager.winModal?.openModal(winDescription, cashWin);
        await this.game.assetsManager.winModal?.modalSleep();
	};

    changeWin= (win: number) => {
        this.game.assetsManager.infoBar?.winBar.changeValue(win)
        this.game.assetsManager.footerForMobileHorizontal?.changeWin(win)
        this.game.assetsManager.footerForMobileVertical?.changeWin(win)
        getEvent(EventType.CHANGE_WIN).send(win)
    }

    turboListener = () => {
        getEvent(EventType.ENABLE_TURBO_MODE).subscribe((value) => {
            localStorage.setItem('isTurboEnabled', JSON.stringify(value))
        })
    }
}
