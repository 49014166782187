import { Base } from './Base';
import { ApiResponse, DebugReques, PlayResponse, WhoAmI } from "./types";
import { EventType, getEvent } from '../GameEventBus';

export default class Public extends Base {

    async play(bet: number, command: string = "", limitReq?: {}): Promise<ApiResponse<PlayResponse>> {
        const payload = {
            bet: bet,
            command: command,
            limitReq : limitReq

        }
        return new Promise(async(resolve, reject) => {
            try {
                const response = await this.request.post<ApiResponse<PlayResponse>>(
                    `/user/play`,
                    payload
                );

                return resolve (response.data);
            } catch (e: any) {
                return reject(e.response.data)
            }
        })


    }

    async playDebug(data: DebugReques): Promise<ApiResponse<PlayResponse>> {
        const payload = data;
        return new Promise(async(resolve, reject) => {
            try {
                const response = await this.request.post<ApiResponse<PlayResponse>>(
                    `/user/debugReels`,
                    payload
                );
                return resolve(response.data);
            } catch (e) {
                return reject(e)
            }
        })


    }

    async initialize() {
        try {
            const response = await this.request.get<WhoAmI>(
                `/user/initialize`,
            );
            getEvent(EventType.AUTH_IS_SUCCESS).send(true);
            return response.data;
        } catch (e) {
            console.error("INITIALIZE ERROR --->", e)
            getEvent(EventType.AUTH_IS_SUCCESS).send(false);
        }

    }
}

