import { FC } from 'react';
import styled from 'styled-components';
import { respondHeightFrom } from '../../../styles/mixins';

interface IProps {
	fourthFeaturePrice: number;
	fifthFeaturePrice: number;
}

const RulesThirdPage: FC<IProps> = ({
	fourthFeaturePrice,
	fifthFeaturePrice,
}) => {
	return (
		<Container>
			<Title>Buy extra picks:</Title>

			<Text>
				Players can purchase extra boost picks for their free spins session.
			</Text>
			<Text>The maximum number of picks is 5.</Text>
			<Text>The prize of the 4th picks is {fourthFeaturePrice}.</Text>
			<Text>The prize of the 5th pick is {fifthFeaturePrice}.</Text>
			<Text>
				Extra picks purchase deactivates after the first chest has been picked
			</Text>

			<br />
			<br />

			<Title>Buy Bonus:</Title>

			<Text>
				Players can purchase free spins with 3-5 feature picks for 100 x bet.
			</Text>

			<br />
		</Container>
	);
};

export default RulesThirdPage;

const Title = styled.h2`
	color: #fff;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	padding-bottom: 20px;
	${respondHeightFrom.base`
		padding-bottom: 0;
	`}
`;

const Container = styled.div`
	flex-direction: column;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
`;

const Text = styled.div`
	padding: 2px;
	color: #fff;
	display: flex;
	font-weight: 700;
	font-size: 18px;
`;
